import React from 'react';

interface RedBadgeProps {
  text: string;
  num?: number;
  margin?: string;
}

const RedBadge: React.FC<RedBadgeProps> = ({ text, num, margin }) => {
  return (
    <span
      style={{
        color: 'red',
        padding: '5px 10px',
        border: '1px solid red',
        borderRadius: '6px',
        fontSize: '13px',
        fontWeight: 600,
        display: 'inline-block',
        margin: margin ? margin : '0'
      }}
    >
      {text} {num}
    </span>
  );
};

export default RedBadge;
