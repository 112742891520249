// import Card001 from '../components/Card001';
import Card002 from '../components/Card002';
// import Card003 from '../components/Card003';
import Card004 from '../components/Card004';
import Card005 from '../components/Card005';
import Card006 from '../components/Card006';  //<- 3D R3F
import Card007 from '../components/Card007';
import Card011 from '../components/Card011';
import Card013 from '../components/Card013';
import Card014 from '../components/Card014';
import Card015 from '../components/Card015';
import Card016 from '../components/Card016';
import Card019 from '../components/Card019';
// import Card020 from '../components/Card020'; //<- "ACROSS THE WORLD" SVG
import Card021 from '../components/Card021';
import Card022 from '../components/Card022';
import Card023 from '../components/Card023';
import Card024 from '../components/Card024';
import Card025 from '../components/Card025';
import Card026 from '../components/Card026';
import Card027 from '../components/Card027';
import Card028 from '../components/Card028';
import Card029 from '../components/Card029';
import Card030 from '../components/Card030';

import Card031 from '../components/Card031';
import Card032 from '../components/Card032';

export const cardData = [


  {
    component: Card031,
    cardId: 'HB031',
    title: 'Hotter',
    is3d: true,
    price: 1.99,
    artist: 'JK Kim'
  },
  

  {
    component: Card030,
    cardId: 'HB030',
    title: 'Beeyutiful',
    is3d: true,
    price: 1.99,
    artist: 'Valeria Guerra'
  },

  {
    component: Card029,
    cardId: 'HB029',
    title: 'Queen',
    is3d: true,
    price: 1.99,
    artist: 'Matheus Mesquita'
  },

  {
    component: Card023,
    cardId: 'HB023',
    title: 'Hauling',
    is3d: true,
    price: 1.99,
    artist: 'Hùng Hoàng'
  },

  {
    component: Card021,
    cardId: 'HB021',
    title: 'Drinks',
    is3d: true,
    price: 1.99,
    artist: 'Joris van Raaij'
  },

  {
    component: Card032,
    cardId: 'HB032',
    title: 'Money',
    is3d: true,
    price: 1.99,
    artist: 'Zam'
  },
 

  {
    component: Card007,
    cardId: 'HB007',
    title: 'Long Time Ago',
    is3d: true,
    price: 1.99,
  },




  {
    component: Card022,
    cardId: 'HB022',
    title: 'Drop',
    is3d: true,
    price: 1.99,
    artist: 'Bennett Feely'
  },



  {
    component: Card019,
    cardId: 'HB019',
    title: 'Bumpin\'',
    is3d: true,
    price: 1.99,
    artist: 'mograph.ix'
  },

  {
    component: Card013,
    cardId: 'HB013',
    title: 'Videogame',
    is3d: true,
    price: 1.99,
  },


  {
    component: Card027,
    cardId: 'HB027',
    title: 'Thexthy',
    is3d: true,
    price: 1.99,
  },

  {
    component: Card026,
    cardId: 'HB026',
    title: 'Six',
    is3d: true,
    price: 1.99,
    artist: 'Tom Fabre'
  },

  {
    component: Card024,
    cardId: 'HB024',
    title: 'Jacked',
    is3d: true,
    price: 1.99,
    artist: 'Yellow Slice Design'
  },


  {
    component: Card025,
    cardId: 'HB025',
    title: 'Drink Anyway',
    is3d: true,
    price: 1.99,
    artist: 'Ameen Shahid'
  },


  {
    component: Card006,
    cardId: 'HB006',
    title: '3d',
    is3d: true,
    price: 1.99,
  },


  // {
  //   component: Card020,
  //   cardId: 'HB020',
  //   title: 'World',
  //   is3d: true,
  //   price: 1.99,
  //   artist: 'LK Jing'
  // },

  {
    component: Card028,
    cardId: 'HB028',
    title: 'With Me',
    is3d: true,
    price: 1.99,
  },
  

  {
    component: Card016,
    cardId: 'HB016',
    title: 'Today',
    is3d: false,
    price: 1.99,
  },

  {
    component: Card015,
    cardId: 'HB015',
    title: 'Cereal',
    is3d: false,
    price: 1.99,
  },

  {
    component: Card014,
    cardId: 'HB014',
    title: 'Smartphone',
    is3d: false,
    price: 1.99,
  },



  {
    component: Card011,
    cardId: 'HB011',
    title: 'Old AF',
    is3d: true,
    price: 1.99,
  },


  {
    component: Card005,
    cardId: 'HB005',
    title: 'Friendship',
    is3d: false,
    price: 1.99,
  },

  {
    component: Card004,
    cardId: 'HB004',
    title: 'My Voice',
    is3d: false,
    price: 1.99,
  },

  // {
  //   component: Card003,
  //   cardId: 'HB003',
  //   title: 'Drink Anyway',
  //   is3d: false,
  //   price: 1.99,
  // },

  {
    component: Card002,
    cardId: 'HB002',
    title: 'Hotter',
    is3d: false,
    price: 1.99,
  },

  // {
  //   component: Card001,
  //   cardId: 'HB001',
  //   title: 'Thexthy',
  //   is3d: false,
  //   price: 1.99,
  // },
];

export function useCardData() {
  return cardData;
}

