import React from 'react';
import styled from 'styled-components';

import Outer from './Outer';
import Inner from './Inner';
import { useRouteMatch } from 'react-router-dom';
import Watermark from './Watermark';
import SupercardCornerBadgeSVGSC from './SupercardCornerBadgeSVG_SC-white-text';
// import { Lottie } from '@crello/react-lottie';
import { Lottie } from '@crello/react-lottie';
import animationData from '../lottie/27182-dollar-bills-animation.json';

import {
  ToFromProps,
  CardBodyProps,
  BackgroundImageProps,
} from '../interfaces/cardInterfaces';

import './card-styles.scss';


const To = styled.span<ToFromProps>`
  position: absolute;
  width: 96%;
  top: 10%;
  margin: 0 0 0 0;
  z-index: 1;
  transform-origin: top left;
  font-size: ${(props) => props.cardWidthInPx / 9}px;
  font-weight: 700;
  text-align: center;
  color: ${(props) => props.textColor};
  letter-spacing: ${(props) => (props.cardWidthInPx > 160 ? '0' : '0')};
  display: inline-block;
  height: ${(props) => props.cardWidthInPx / 1.85}px;
  overflow: hidden;
  line-height: 1.05;
`;
To.defaultProps = {
  'data-id': 'To',
};

const From = styled.span<ToFromProps>`
  position: absolute;
  width: 96%;
  top: 77%;
  margin: 0 0 0 0;
  z-index: 1;
  font-size: ${(props) => props.cardWidthInPx / 9}px;
  text-align: center;
  color: ${(props) => props.textColor};
  letter-spacing: ${(props) => (props.cardWidthInPx > 160 ? 0 : 0)};
  font-weight: 700;
  display: inline-block;
  height: ${(props) => props.cardWidthInPx / 3.1}px;
  overflow: hidden;
  line-height: 1;
`;
From.defaultProps = {
  'data-id': 'From',
};

const BackgroundImage = styled.img<BackgroundImageProps>`
  display: block;
  position: absolute;
  width: ${(props) => props.cardWidthInPx}px;
  opacity: 1;
  border-radius: 8px;
  height: 100%;
  object-fit: cover;
  z-index: 0;
`;
BackgroundImage.defaultProps = {
  'data-id': 'BackgroundImage',
};

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
    className: 'workout-class',
    id: 'workout-id',
  }
};

const Card: React.FC<CardBodyProps> = ({ to, from, cardWidthInPx }) => {
  let matchSelectedRoute = useRouteMatch('/selected');
  let matchEmailRoute = useRouteMatch('/email');

  // const [speed, setSpeed] = useState<number|undefined>();
  // useEffect(() => {
  //   setSpeed(1);
  // }, []);

  return (
    <>
      <Outer cardWidthInPx={cardWidthInPx}>
        <Inner bcgColor={'linear-gradient(0deg, rgb(0, 151, 95) 0%, rgb(217, 255, 253) 100%)'} justifyContent={'center'}>
          
          {(matchSelectedRoute || matchEmailRoute) && (
            <Watermark
              width={52}
              top={70}
              left={47}
            />
          )}

          <To textColor={'black'} cardWidthInPx={cardWidthInPx}>
            {`Happy Birthday ${to && to}. Our friendship is so money.`}
          </To>

          <div
            className="lottie-wrapper money"
            style={{ width: '140%', alignSelf: 'center', position:'relative', top: '24px' }}
          >
            <Lottie
              config={defaultOptions}
              // width={'85%'}
              // isStopped={true}
              // isPaused={true}
              // speed={speed}
            />
          </div>

          <From textColor={'white'} cardWidthInPx={cardWidthInPx}>
            {from && from}
          </From>

          <SupercardCornerBadgeSVGSC widthinpx={cardWidthInPx} />
        </Inner>
      </Outer>
    </>
  );
};

export default Card;
