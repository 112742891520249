import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import Outer from './Outer';
import Inner from './Inner';
import { useRouteMatch } from 'react-router-dom';
import Watermark from './Watermark';
import SupercardCornerBadgeSVGSC from './SupercardCornerBadgeSVG_SC-white-text';
import { Lottie } from '@crello/react-lottie';
import animationData from '../lottie/77-im-thirsty.json';
import {
  ToFromProps,
  CardBodyProps,
  BackgroundImageProps,
} from '../interfaces/cardInterfaces';
import './card-styles.scss';

const To = styled.span<ToFromProps>`
  position: absolute;
  width: 96%;
  top: 10%;
  margin: 0 0 0 0;
  z-index: 1;
  transform-origin: top left;
  font-size: ${(props) => props.cardWidthInPx / 9}px;
  font-weight: 700;
  text-align: center;
  color: ${(props) => props.textColor};
  letter-spacing: ${(props) => (props.cardWidthInPx > 160 ? '0' : '0')};
  display: inline-block;
  height: ${(props) => props.cardWidthInPx / 1.85}px;
  overflow: hidden;
  line-height: 1;
`;
To.defaultProps = {
  'data-id': 'To',
};

const From = styled.span<ToFromProps>`
  position: absolute;
  width: 96%;
  top: 76%;
  margin: 0 0 0 0;
  z-index: 1;
  font-size: ${(props) => props.cardWidthInPx / 9.25}px;
  text-align: center;
  color: ${(props) => props.textColor};
  letter-spacing: ${(props) => (props.cardWidthInPx > 160 ? 0 : 0)};
  font-weight: 700;
  display: inline-block;
  height: ${(props) => props.cardWidthInPx / 3.1}px;
  overflow: hidden;
  line-height: 1;
`;
From.defaultProps = {
  'data-id': 'From',
};

const BackgroundImage = styled.img<BackgroundImageProps>`
  display: block;
  position: absolute;
  width: ${(props) => props.cardWidthInPx}px;
  opacity: 1;
  border-radius: 8px;
  height: 100%;
  object-fit: cover;
  z-index: 0;
`;
BackgroundImage.defaultProps = {
  'data-id': 'BackgroundImage',
};

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
    className: 'drinks',
    id: 'some-id',
  }
};

const CardBody021: React.FC<CardBodyProps> = ({ to, from, cardWidthInPx }) => {
  let matchSelectedRoute = useRouteMatch('/selected');
  let matchEmailRoute = useRouteMatch('/email');

  const [speed, setSpeed] = useState<number|undefined>();

  useEffect(() => {
    setSpeed(1.8);
  }, []);

  return (
    <>
      <Outer cardWidthInPx={cardWidthInPx}>
        <Inner bcgColor={'hsla(263, 47%, 16%, 1)'} justifyContent={'center'}>
          
          {(matchSelectedRoute || matchEmailRoute) && (
            <Watermark
              width={52}
              top={70}
              left={47}
            />
          )}

          <To textColor={'#ff2525'} cardWidthInPx={cardWidthInPx}>
            {`Happy Birthday ${to && to}! Drinks on me.`}
          </To>

          <div className="lottie-wrapper drinks" style={{width:'130%', alignSelf: 'center' }}>
            <Lottie
              config={defaultOptions}
              width={'100%'}
              // isStopped={this.state.isStopped}
              // isPaused={this.state.isPaused}
              speed={speed}
            />
          </div>

          <From textColor={'white'} cardWidthInPx={cardWidthInPx}>
            {from && from}
          </From>

          <SupercardCornerBadgeSVGSC widthinpx={cardWidthInPx} />
        </Inner>
      </Outer>
    </>
  );
};

export default CardBody021;
