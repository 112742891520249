// COLOR PICKER: https://ant.design/docs/spec/colors/

export const gray = {
  1: '#ffffff',
  2: '#fafafa',
  3: '#f5f5f5',
  4: '#e8e8e8',
  5: '#d9d9d9',
  6: '#bfbfbf',
  7: '#8c8c8c',
  8: '#595959',
  9: '#262626',
  10: '#000000'
};

export const daybreakBlue = {
  1: '#e6f7ff',
  2: '#bae7ff',
  3: '#91d5ff',
  4: '#69c0ff',
  5: '#40a9ff',
  6: '#1890ff',
  7: '#096dd9',
  8: '#0050b3',
  9: '#003a8c',
  10: '#002766'
};

export const polarGreen = {
  1: '#f6ffed',
  2: '#d9f7be',
  3: '#b7eb8f',
  4: '#95de64',
  5: '#73d13d',
  6: '#52c41a',
  7: '#389e0d',
  8: '#237804',
  9: '#135200',
  10: '#092b00'
};

export const dustRed = {
  1: '#fff1f0',
  2: '#ffccc7',
  3: '#ffa39e',
  4: '#ff7875',
  5: '#ff4d4f',
  6: '#f5222d',
  7: '#cf1322',
  8: '#a8071a',
  9: '#820014',
  10: '#5c0011'
};

export const volcano = {
  1: '#fff2e8',
  2: '#ffd8bf',
  3: '#ffbb96',
  4: '#ff9c6e',
  5: '#ff7a45',
  6: '#fa541c',
  7: '#d4380d',
  8: '#ad2102',
  9: '#871400',
  10:'#610b00'
};

export const sunsetOrange = {
  1: '#fff7e6',
  2: '#ffe7ba',
  3: '#ffd591',
  4: '#ffc069',
  5: '#ffa940',
  6: '#fa8c16',
  7: '#d46b08',
  8: '#ad4e00',
  9: '#873800',
  10:'#612500'
};


export const bodyCopyStyles = {
  fontSize:'16px'
};
