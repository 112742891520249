import { combineReducers, applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
// import { actionTypes } from './actions';
import { cardData } from '../utils/useCardData.js';

const initialState = cardData;

const middleware = [thunk];

// passed into rootReducer
const cardReducer: (state: Array<{}>, action: {}) => Array<{}> = (
  state = initialState,
  action
) => {
  // const { type, payload } = action;
  const { type }: { type?: string } = action;

  switch (type) {
    // case actionTypes.ADD_CARD_ID:
    //   return [
    //     ...state,
    //     cardId: payload,
    //   ];

    // case actionTypes.ADD_TO_FROM_INPUT_VALUES:
    //   return {
    //     ...state,
    //     to: payload.to,
    //     from: payload.from,
    //   };

    // case actionTypes.ADD_EMAIL_ADDRESS:
    //   return {
    //     ...state,
    //     email: payload,
    //   };

    // case actionTypes.ADD_CARDID_TO_FROM:
    //   return {
    //     ...state,
    //     cardId: payload.cardId && payload.cardId,
    //     to: payload.to,
    //     from: payload.from,
    //   };

    default:
      return state;
  }
};

// The top-level state object
export interface ApplicationState {
  // cardReducer: any;
}

// Pass in all slice reducers. State structure in Redux DevTools (state.cardReducer, etc)
const rootReducer = combineReducers<ApplicationState>({
  cardReducer: cardReducer,
});

const store = createStore(
  rootReducer,
  {},
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
