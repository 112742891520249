import { createGlobalStyle } from 'styled-components';
import { gray } from '../theme';

interface Props{
  light?: boolean;
  'data-id'?: string;
}
const StyledComponentsGlobalStyles = createGlobalStyle<Props>`
  body {
    margin: 0;
    background-color: ${props => (props.light ? gray[3] : gray[9])};
  }
`;
StyledComponentsGlobalStyles.defaultProps = {
  'data-id': 'StyledComponentsGlobalStyles'
};

export default StyledComponentsGlobalStyles;
