import React from 'react';
import { dustRed } from '../theme';
import './topAppBar.scss';
import {
  // Link,
  // useHistory,
  useRouteMatch,
} from 'react-router-dom';

import styled from 'styled-components';
// import Icon from 'antd/es/icon';

import CaretAndBack from './CaretAndBack';
import { Link } from 'react-router-dom';
import { ReactComponent as IGLogo } from '../svgs/ig2.svg';
import { ReactComponent as FBLogo } from '../svgs/fb.svg';
import useWindowSize from '../utils/useWindowSize';

interface ContainerProps {
  'data-id'?: string;
}
const Container = styled.div<ContainerProps>`
  height: 48px;
  background-color: black;
  padding: 0;
  margin: 0 0 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
Container.defaultProps = {
  'data-id': 'TopAppBar-Container',
};

interface SideStripProps {
  'data-id'?: string;
}
const SideStrip = styled.div<SideStripProps>`
  height: 48px;
  width: 4px;
  background-color: ${dustRed[6]};
  margin: 0 8px 0 0;
`;
SideStrip.defaultProps = {
  'data-id': 'TopAppBar-SideStrip',
};

const TopAppBar: React.FC<{ className: string; id: string }> = () => {
  let match = useRouteMatch([
    '/selected',
    '/login',
    '/email',
    '/submitemail',
    '/howitworks',
    '/contact',
    '/faq',
    '/payment',
  ]);

  const size = useWindowSize();

  return (
    <Container>
      <div className="left-side-container">
        <SideStrip />

        {match && <CaretAndBack />}
      </div>

      <div className="links-container">
        

        <Link className="top-bar-item" to="/howitworks">
          <span>How It Works</span>
        </Link>

        {size.width > 440 && (
          <>
            <Link className="top-bar-item" to="/faq">
              <span className="wide">FAQ</span>
            </Link>
          </>
        )}

        <Link className="top-bar-item" to="/contact">
          <span>Contact</span>
        </Link>

        <a
          className="top-bar-item social-icon"
          href="https://www.instagram.com/supercardco/"
          rel="noopener noreferrer"
          target="_blank"
        >
          <IGLogo />
        </a>

        <a
          className="top-bar-item social-icon"
          href="https://www.facebook.com/Supercard-113285887056608/"
          rel="noopener noreferrer"
          target="_blank"
        >
          <FBLogo />
        </a>
      </div>

      {/*{console.log("MATCH:" ,match)}*/}
    </Container>
  );
};

export default TopAppBar;
