import React from 'react'
import RedBadge from '../components/RedBadge';
import Input from 'antd/es/input/Input';

interface LivePreviewProps {
  // handleCardClick?: () => void;
  // setCardId?: any;
  to: string;
  from: string;
  setTo: any;
  setFrom: any;
}

const LivePreview: React.FC<LivePreviewProps> = ({ to, from, setTo, setFrom }) => {
  

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'to') {
      setTo(e.target.value);
    }
    if (e.target.name === 'from') {
      setFrom(e.target.value);
    }
  };
  
  
  return (
          
<>
      <RedBadge margin={'24px 0 0 0'} text={'OPTIONAL: LIVE PREVIEW'} />

      <h3
        style={{
          fontSize: '22px',
          lineHeight: 1.25,
          margin: '10px 0 12px 0',
          maxWidth: '680px',
        }}
      >
        Add "To" and "From" names below to preview all cards at once:{' '}
      </h3>

      <div className="input-wrapper">
        <label style={{ fontSize: '13px' }}>To:</label>
        <br />
        <Input
          name={'to'}
          onChange={handleInputChange}
          placeholder={`Friend's name `}
          value={to}
          type={'text'}
          size={'large'}
          style={{
            margin: '2px 0 8px 0',
            maxWidth: '380px',
            color: 'black',
            fontWeight: 500
          }}
        />
      </div>

      <div className="input-wrapper">
        <label style={{ fontSize: '13px' }}>From:</label>
        <br />
        <Input
          name={'from'}
          onChange={handleInputChange}
          placeholder={`Your name (+ optional short msg)`}
          value={from}
          type={'text'}
          size={'large'}
          style={{
            display: 'block',
            margin: '2px 0 8px 0',
            maxWidth: '380px',
            color: 'black',
            fontWeight: 500
          }}
        />
      </div>

      <span
        style={{
          display: 'block',
          margin: '0 0 16px 0',
          maxWidth: '380px',
          fontSize: '13px',
          lineHeight: 1.4,
        }}
      >
        <span role="img" aria-label="lock icon">
          😎{' '}
        </span>
        <strong>ProTip</strong>: You can include short messages, like "Love" or
        "See U Soon".
      </span>
</>
      
    );
}


export default LivePreview;