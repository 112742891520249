// //**DEFINE** THE MAILGUN SEND EMAIL REQUEST
// Dispatch<SetStateAction<null>>
// type Dispatch<A> = (value: A) => void;
// type SetStateAction<A> = () => void;
import { Dispatch, SetStateAction } from 'react';
import { UserObjectProps } from '../interfaces/cardInterfaces';


const checkMongoIfUserExists = async (
  cardId: string,
  to: string,
  from: string,
  _id: string,
  encodedEmailFromQuery: string,
  decodedEmail: string,
  setUserDataFromMongo: Dispatch<SetStateAction<UserObjectProps | null>>,
  is3d: string
) => {
  //CHECK IF MONGODB DOCUMENT IS THERE:
  try {
    const res = await fetch('/api/checkForUser', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        id: _id,
      }),
    });

    // 6. Convert the response to JSON:
    const userDocOrNull = await res.json();

    //if the id sent into the req DOES match a doc from MongoDB, log this
    // if (data && data._id && (data._id === _id)) {

    // SCHEMA OF RETURNED OBJECT if it is not NULL -> `{ _id: '5d273f0' }`
    if (userDocOrNull && userDocOrNull._id) {
      console.log(
        '🔵 Unexpectedly, this _id DOES exist, so I will NOT run addDataToMongo, and NOT sendMailgunEmail.'
      );
    }

    //if the id sent into the req does NOT match a doc from MongoDB, log this, send Mailgun email, and add data to Mongo
    // if (data && data._id && (data._id !== _id)) {
    if (userDocOrNull === null) {
      console.log(
        '✅ 1. Good news from checkMongoIfUserExists. The response was NULL. The _id does NOT already exist. So I will run addDataToMongo, and then sendMailgunEmail.'
      );
      await addDataToMongo(
        cardId,
        to,
        from,
        _id,
        decodedEmail,
        setUserDataFromMongo,
        is3d
      );
      await sendMailgunEmail(
        cardId,
        to,
        from,
        _id,
        encodedEmailFromQuery,
        is3d
      );
    }

    if (userDocOrNull && userDocOrNull.error) {
      console.log(
        "Hmm. Mongo's response is this ERROR object: ",
        userDocOrNull
      );
    }
    // await setUserDataFromMongo(data);

    // console.log('checkMongoIfUserExists FINISHED!');
    console.log('Actual raw response from Mongo: ', userDocOrNull);

    // THIS CALL RETURNS NULL: TOO SOON TO READ STATE!
    // console.log('userDataFromMongo1:', userDataFromMongo);
  } catch (e) {
    console.error('ERROR FROM checkMongoIfUserExists FUNCTION!:', e);
  }
};


const sendMailgunEmail = async (
  cardId: string,
  to: string,
  from: string,
  _id: string,
  encodedEmailFromQuery: string,
  is3d: string
) => {
  try {
    // Send a req, receive a response, store it in `res`:
    const res = await fetch(
      `/api/sendemail?card=${cardId}&to=${to}&from=${from}&_id=${_id}&email=${encodedEmailFromQuery}&is3d=${is3d}`,
      {
        method: 'POST',
        // method: "GET",
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify({}),
      }
    );
    // Convert the res (which is an OBJECT) to JSON. Store the JSON as `data`:
    const data = await res.json();

    // 3. Do something(Log) the response from server
    console.log(
      '✅ 3: Good news from sendMailgunEmail. Response data from mailgun:',
      data
    );
  } catch (e) {
    console.error('ERROR FROM MAILGUN REQUEST!:', e);
  }
};


async function addDataToMongo(
  cardId: string,
  to: string,
  from: string,
  _id: string,
  decodedEmail: string,
  setUserDataFromMongo: (data: {}) => void,
  is3d: string
) {
  // 5. Receive the response

  try {
    // POST request to serverless func:
    const res = await fetch('/api/saveNewCard', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',

        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      // SEND ALL DATA TO MONGO via the REQUEST BODY
      body: JSON.stringify({
        cardId: cardId,
        to: to,
        from: from,
        _id: _id,
        email: decodedEmail,
        urlCode: _id,
        shortUrl: `https://supercardco.com/card/${_id}`,
        is3d: is3d,
      }),
    });

    // 6. Convert the response to JSON:
    //   `data` === newly saved mongo document object
    const data = await res.json();

    if (data) {
      setUserDataFromMongo(data);
      console.log(
        '✅ 2: Good news from addDataToMongo: Newly-saved Mongo document object: ',
        data
      );
    }

    if (!data) {
      console.error(
        'Bad news from addDataToMongo: Something went wrong saving the document at mongo. Or something went wrong getting the response back from the /api/saveNewCard server-side endpoint.'
      );
    }
  } catch (e) {
    console.error('error_from_addDataToMongo_func: ', e);
  }
}

export default checkMongoIfUserExists;
