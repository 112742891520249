import React from 'react';
import styled from 'styled-components';
import { ReactComponent as WelcomeCircle } from '../svgs/welcome-circle.svg';
import { ReactComponent as OldIcon } from '../svgs/oldicon.svg';

import Outer from './Outer';
import Inner from './Inner';
import { useRouteMatch } from 'react-router-dom';
import Watermark from './Watermark';
import SupercardCornerBadgeSVGSC from './SupercardCornerBadgeSVG_SC-white-stroke';
import {
  ToFromProps,
  CardBodyProps,
  BackgroundImageProps,
} from '../interfaces/cardInterfaces';

const To = styled.span<ToFromProps>`
  position: absolute;
  top: 7%;

  text-align: center;
  width: 95%;

  letter-spacing: ${(props) => (props.cardWidthInPx > 160 ? 0 : '0')};

  font-size: ${(props) => props.cardWidthInPx / 11}px;
  color: ${(props) => props.textColor};

  margin: 0 0 0 0;
  z-index: 1;
  font-weight: 500;

  display: inline-block;
  height: ${(props) => props.cardWidthInPx / 4.86}px;
  overflow: hidden;
  line-height: 1.15;
`;
To.defaultProps = {
  'data-id': 'To',
};

const From = styled.span<ToFromProps>`
  position: absolute;
  top: 82%;

  text-align: center;
  width: 100%;

  letter-spacing: ${(props) => (props.cardWidthInPx > 160 ? 0 : '0')};

  font-size: ${(props) => props.cardWidthInPx / 11}px;
  color: ${(props) => props.textColor};
  font-weight: 500;
  margin: 0 0 0 0;
  z-index: 1;

  display: inline-block;
  height: ${(props) => props.cardWidthInPx / 5.7}px;
  overflow: hidden;
  line-height: 1.1;
`;
From.defaultProps = {
  'data-id': 'From',
};

const BackgroundImage = styled.img<BackgroundImageProps>`
  display: block;

  position: absolute;
  width: ${(props) => props.cardWidthInPx}px;
  top: 50%;
  margin-top: -${(props) => props.cardWidthInPx * 0.5}px;
  transform: scale(0.5);

  z-index: 0;
`;
BackgroundImage.defaultProps = {
  'data-id': 'BackgroundImage',
};

const WelcomeCircleSC = styled(({ cardWidthInPx, ...rest }) => (
  <WelcomeCircle {...rest} />
))`
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -${(props) => props.cardWidthInPx * 0.5}px;

  animation: rotation 20s infinite linear;
`;

const OldIconSC = styled(({ cardWidthInPx, ...rest }) => <OldIcon {...rest} />)`
  display: block;
  position: absolute;
  top: 68%;
  height: 25%;
  margin-top: -${(props) => props.cardWidthInPx * 0.5}px;
`;

const CardBody011: React.FC<CardBodyProps> = ({ to, from, cardWidthInPx }) => {
  let matchSelectedRoute = useRouteMatch('/selected');
  let matchEmailRoute = useRouteMatch('/email');

  return (
    <>
      {/*<StyledComponentsGlobalStyles />*/}

      <Outer cardWidthInPx={cardWidthInPx}>
        {/*<Inner bcgColor={'hsla(259, 25%, 30%, 1)'}>*/}
        <Inner bcgColor={'hsla(345, 100%, 48%, 1)'}>
          {/*<Inner bcgColor={'hsla(348, 87%, 39%, 1)'}>*/}
          {(matchSelectedRoute || matchEmailRoute) && (
            <Watermark
            // ALL OPTIONAL PROPS (default values are applied in the TERNARY of WatermarkImgSC.
            // width={70}
            // top={60.5} left={29.5}
            />
          )}

          <To textColor={'cyan'} cardWidthInPx={cardWidthInPx}>
            {'Happy birthday'.toUpperCase()}
            <br/>
            {to && to.toUpperCase()}
          </To>

          <WelcomeCircleSC id={'welcome'} cardWidthInPx={cardWidthInPx} />

          <OldIconSC id={'old-icon'} cardWidthInPx={cardWidthInPx} />

          <From textColor={'cyan'} cardWidthInPx={cardWidthInPx}>
            &ndash; {from && from}
          </From>

          {/*<Link to={'/'}>*/}
          <SupercardCornerBadgeSVGSC widthinpx={cardWidthInPx} />
          {/*</Link>*/}

          {/*<From textColor={'#DF974B'} cardWidthInPx={cardWidthInPx}>From, {from}</From>*/}
        </Inner>
      </Outer>
    </>
  );
};

export default CardBody011;
