import styled from 'styled-components';

interface OuterProps {
  cardWidthInPx: number;
  'data-id'?: string;
}
const Outer = styled.div<OuterProps>`

  background: white;
  position: relative;
  width: ${props=>props.cardWidthInPx}px;
  border-radius: 8px;
  &::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 164%;
    border-radius: 8px
  };
  
  box-shadow: 4px 10px 15px 0px rgba(0,0,0,0.15);
`;
Outer.defaultProps = {
  'data-id': 'Outer'
};

export default Outer;
