import React from 'react'
import useWindowSize from '../utils/useWindowSize';
import LaurelWreathQuote from '../components/LaurelWreathQuote';

interface TestimonialsProps {

}

const Testimonials: React.FC<TestimonialsProps> = () => {
  
  const size = useWindowSize();

  return (
      
    <div
    className="laurel-padding-container"
    style={{
      margin: '40px 0',
    }}
  >
    <div
      className="laurel-flex-container"
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between'
      }}
    >
      <LaurelWreathQuote
        name="Molly"
        rightMarginInPx={14}
        text="Much better than other e-cards."
      />
      <LaurelWreathQuote
        name="Samuel"
        rightMarginInPx={size.width > 440 ? 14 : 0}
        text="I would pay even more for these."
      />
      <LaurelWreathQuote
        name="Soo Mean"
        rightMarginInPx={14}
        text="I like sending birthday cards now."
      />
      <LaurelWreathQuote
        name="Emmett"
        rightMarginInPx={size.width > 440 ? 14 : 0}
        text="Whoa these cards are weird."
      />
    </div>
  </div>

    );
}

export default Testimonials;