import styled from 'styled-components';


interface StyledH2Props {
  margin?: string;
  'data-id'?: string;
}
const StyledH2 = styled.h2<StyledH2Props>`
  font-size: 22px;
  padding: 6px 0 16px 0;
  margin: ${(props) => (props.margin ? props.margin : '0 0 16px 0')};
  line-height: 1.25;
  max-width: 450px;
`;
StyledH2.defaultProps = {
  'data-id': 'Home__StyledH2',
};

export default StyledH2;