import styled from 'styled-components';

interface InnerProps {
  bcgColor?: string;
  justifyContent?: string;
  'data-id'?: string;
}
// 🕹 🎛 🖲 CONTROL CARD LAYOUT HERE
const Inner = styled.div<InnerProps>`
  background: ${props => props.bcgColor || 'white'};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${props => props.justifyContent ? props.justifyContent : 'space-between' };
  border-radius: 8px;
  
  overflow: hidden;
`;
Inner.defaultProps = {
  'data-id': 'Inner'
};

export default Inner;
