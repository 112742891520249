import React from 'react';
// import styled from 'styled-components';

import PageWrapper from '../components/PageWrapper';
import StyledComponentsGlobalStyles from '../components/StyledComponentsGlobalStyles';
import SupercardHeaderLockup from '../components/SupercardHeaderLockup';
import { Helmet } from 'react-helmet';
import './pages.scss';
import './faq.scss';

const Faq = () => {
  return (
    <PageWrapper>
      <Helmet>
        <title>FAQ</title>
      </Helmet>

      <StyledComponentsGlobalStyles light />

      <SupercardHeaderLockup lightText={false} marTop={20} />

      
      <div className="faq">

        <h1 className={'page-title'}>FAQ</h1>

        <div className={'text-block'}>
          <p>How much are Supercards?</p>
          <p>All Supercards are priced at $1.99 each.</p>
        </div>

        <div className={'text-block'}>
          <p>What are Supercards?</p>
          <p>
            Each Supercard is essentially a one-page website, customized by you.
            Supercards are published to unique web address URLs. This makes
            Supercards easily shareable via email, chat, and social media
            platforms.
          </p>
        </div>

        <div className={'text-block'}>
          <p>How long are Supercards "live" and stay published to the web?</p>
          <p>
            Supercards are published live to the internet at the moment of
            payment. They are guaranteed to remain published to the internet for
            365 days from the date of purchase.
          </p>
        </div>

        <div className={'text-block'}>
          <p>What do I receive for $1.99?</p>
          <p>
            After submitting payment, you will receive the link to your
            customized Supercard URL. You can share your Supercard with anyone
            you want, as many times as you want. Supercards are always live, and
            remain published to the internet for 365 days from the date of
            purchase.
          </p>
        </div>

        <div className={'text-block'}>
          <p>
            Can I purchase one Supercard and send to as many people as I want?
          </p>
          <p>
            You can share your Supercard with anyone you want, as many times as
            you want. Supercards are always live, and never expire. But
            remember, your Supercard will always display the customized names
            you designated at purchase. If you want to send a Supercard
            customized with a second friend's name, you will want to purchase a
            second Supercard.
          </p>
        </div>

        <div className={'text-block'}>
          <p>What is the process for purchasing a Supercard?</p>
          <p>
            Step 1: Pick a card you want to send.
            <br />
            Step 2: Customize your card by entering the "To" and "From" names.
            <br />
            Step 3: Enter your email address, so we may send you your live
            Supercard web URL.
            <br />
            Step 4: Submit secure payment via Stripe.
            <br />
            Step 5: Open your email and share the Supercard URL address to your
            friend.
          </p>
        </div>

        <div className={'text-block'}>
          <p>Do I have to sign up or register?</p>
          <p>
            There is no sign-up or registration step. We only request your email
            address, solely for the purpose of providing you with the unique web
            URL address where your Supercard lives.
          </p>
        </div>

        <div className={'text-block'}>
          <p>
            Is payment secure? Do you store or have access to my credit card
            information?
          </p>
          <p>
            We provide secure payment via Stripe, a well-established and
            highly-secure payment platform. Stripe handles the entire
            transaction. You will be prompted to securely use a credit card, and
            even Google Pay and/or Apple Pay if it's set up on your device.
          </p>
        </div>

        <div className={'text-block'}>
          <p>Are your cards available anywhere else?</p>
          <p>All Supercards are exclusive content.</p>
        </div>

        <div className={'text-block'}>
          <p>What is your refund policy?</p>
          <p>
            We have a user-friendly refund policy. If you would like a refund,
            simply write to us at{' '}
            <a href="mailto:info@supercardco.com">info@supercardco.com</a>. Upon
            refund, please expect your unique card link to expire shortly
            thereafter.
          </p>
        </div>

        <div className={'text-block'}>
          <p>Who made this product?</p>
          <p>
            Supercard was created by Juump LLC. Please reach out with any
            questions or feedback at{' '}
            <a href="mailto:info@supercardco.com">info@supercardco.com</a>.
          </p>
        </div>

      </div>

      <div style={{ height: '50px' }}></div>
    </PageWrapper>
  );
};

export default Faq;
