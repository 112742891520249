/* eslint-disable */
import React, { useState, useEffect } from 'react';
// import {Link} from 'react-router-dom'
import useQuery from '../utils/useQuery';
// import PropTypes from 'prop-types';
// import { useSelector, useDispatch } from 'react-redux';
import SupercardHeaderLockup from '../components/SupercardHeaderLockup';
import PageWrapper from '../components/PageWrapper';
import checkMongoIfUserExists from '../utils/checkMongoIfUserExists';
import { gray, dustRed } from '../theme';
import CopyToClipboard from 'react-copy-to-clipboard';
import Button from 'antd/es/button';
import Spin from 'antd/es/spin';
import {UserObjectProps} from '../interfaces/cardInterfaces'
// import Icon from 'antd/es/icon'
// import {deployedDEVBranch} from '../utils/deployed-dev-branch';

import styled from 'styled-components';
import CopyOutlined from '@ant-design/icons/CopyOutlined'

interface SuperTextProps {
  size?: number;
  'data-id'?: string;
}
const SuperText = styled.p<SuperTextProps>`
  font-size: 24px;
  font-size: ${(props) => (props.size ? props.size : 24)}px;
  color: ${gray[10]};
  margin: 0 0 10px 0;
  max-width: 500px;
`;
SuperText.defaultProps = {
  'data-id': 'SuperText',
  size: 24,
};

const ShareableLinkContainer = styled.button`
  display: inline-block;
  padding: 14px 18px;
  border: 1px solid ${dustRed[6]};
  border-radius: 8px;
  margin: 0 8px 8px 0;
  transition: all 0.5s ease;
  height: 50px;
  position: relative;
  top: 1px;
  span {
    color: ${gray[10]};
    font-size: 14px;
    font-weight: 600;
  }

  &:hover {
    background-color: ${dustRed[6]};

    span {
      color: ${gray[1]};
    }
  }
`;

// const CopyButton = styled(Button)`
// STYLED COMPONENTS PROPS ON DOM ELEMENT HACK:
const CopyButton = styled(({ isCopied, ...rest }) => <Button {...rest} />)`
  && {
    background-color: ${(props) => (props.isCopied ? 'transparent' : 'red')};
    height: 50px;
    border-radius: 8px;
    border: ${(props) =>
      props.isCopied ? `1px solid black` : '1px solid ${dustRed[5]}'};
    span {
      color: ${(props) => (props.isCopied ? 'black' : 'white')};

      font-size: 14px;
      font-weight: 600;
    }
    .anticon.anticon-copy > svg {
      fill: ${(props) => (props.isCopied ? 'black' : 'white')};
    }
    &:hover {
      background-color: ${dustRed[7]};
      border: 1px solid ${dustRed[7]};
    }
    &:hover > span {
      color: ${gray[1]};
    }

    &:hover > .anticon.anticon-copy > svg {
      fill: ${gray[1]};
    }
  }
`;

const Success = () => {
  let query = useQuery();

  const cardId = query.get('card');
  const to = query.get('to');
  const from = query.get('from');
  const _id = query.get('_id');
  const encodedEmailFromQuery = query.get('email');
  const is3d = query.get('is3d');
  const decodedEmail = encodedEmailFromQuery && decodeURIComponent(encodedEmailFromQuery);

  const [userDataFromMongo, setUserDataFromMongo] = useState<UserObjectProps | null>(null);

  // const [copiedURL, setCopiedURL] = useState({value: `https://${window.location.hostname}/card/${_id}`, copied: false});
  // const [copiedURL, setCopiedURL] = useState({value: `https://${window.location.hostname}/card/${_id}`, copied: false});
  const [copiedURL, setCopiedURL] = useState({
    value:
      process.env.NODE_ENV === 'development'
        ? `http://localhost:3000/card/${_id}`
        : `https://${window.location.hostname}/card/${_id}`,
    copied: false,
  });

  // let history = useHistory();
  // let location = useLocation();

  // ON MOUNT, run function which conditionally triggers Mailgun and Mongo requests:
  useEffect(() => {
    // console.log('process.env.NODE_ENV: ', process.env.NODE_ENV);

    //wait for values first, then make the api request
    if (
      cardId &&
      to &&
      from &&
      _id &&
      encodedEmailFromQuery &&
      decodedEmail &&
      setUserDataFromMongo &&
      is3d
    ) {
      // This function checks mongo, AND writes to DB if it doesn't exist. So the data will be there when requested at the /card route.
      checkMongoIfUserExists(
        cardId,
        to,
        from,
        _id,
        encodedEmailFromQuery,
        decodedEmail,
        setUserDataFromMongo,
        is3d
      );
    }

    // console.log('window.location.hostname:', window.location.hostname);
    // console.log('window.location.href:', window.location.href);
    //
    // console.log('window.location:', window.location);
  }, []);

  // Log the userDataFromMongo after it is loaded:
  useEffect(() => {
    console.log('userDataFromMongo, from state:', userDataFromMongo);
  }, [userDataFromMongo]);

  const onCopy = () => {
    setCopiedURL({
      ...copiedURL,
      copied: true,
    });
  };

  return (
    <PageWrapper>
      {userDataFromMongo ? (
        <>
          <SupercardHeaderLockup text={'Success'} />

          <SuperText size={20}>
            Your Supercard has been sent to <strong>{decodedEmail}</strong> for
            easy access.
          </SuperText>

          <SuperText size={20}>
            On their birthday, send your friend this Supercard URL:
          </SuperText>

          <a
            target={'_blank'}
            href={
              process.env.NODE_ENV === 'development'
                ? `http://localhost:3000/card/${_id}`
                : `https://${window.location.hostname}/card/${_id}`
            }
          >
            <ShareableLinkContainer>
              <span>
                {process.env.NODE_ENV === 'development'
                  ? `http://localhost:3000/card/${_id}`
                  : `https://${window.location.hostname}/card/${_id}`}
                {/* {`https://${window.location.hostname}/card/${_id}`} */}
                {/* {`https://supercardco.com/card/${_id}`} */}
              </span>
            </ShareableLinkContainer>
          </a>

          <CopyToClipboard onCopy={onCopy} text={copiedURL.value}>
            {/*<button>Copy Link</button>*/}
            <CopyButton isCopied={copiedURL.copied} icon={<CopyOutlined />}>
              {copiedURL.copied ? <span>COPIED!</span> : <span>COPY LINK</span>}
            </CopyButton>
          </CopyToClipboard>

          <div style={{ margin: '40px 0 0 0'}}>

            <p style={{ fontSize: '14px'}}>
              Thank you for using Supercard™. Questions or feedback? Please reach us at{' '}<a href="mailto:someone@yoursite.com?subject=Mail from Our Site">info@supercardco.com</a>.
            </p>

          </div>
        </>
      ) : (
        // IF USEROBJECT *NOT* LOADED YET:
        <>
          <p
            style={{
              fontSize: '28px',
              color: 'red',
              fontWeight: 700,
              margin: '32px 0 0 0',
            }}
          >
            Supercard™ loading...
          </p>

          <p style={{ color: 'black', fontSize: '16px', lineHeight: 1.15 }}>
            Stand by. Creation takes about 3 seconds.
          </p>

          <Spin style={{ margin: '32px 0 0 0' }} size="large" />
        </>
      )}
    </PageWrapper>
  );
};

export default Success;
