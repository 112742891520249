import React from 'react';

interface LegalFooterProps {}

export const LegalFooter: React.FC<LegalFooterProps> = () => {
  return (
    <footer role={'article'} style={{color:'gray', padding: '56px 0 16px 0', lineHeight: 1, fontSize: '11.5px' }}>
      <small>
        Site copyright &copy; 2020, Juump LLC. All trademarks, service marks, likenesses and logos on this site are the property of their
        respective owners.
      </small>
    </footer>
  );
};
