
import React from 'react';
// import styled from 'styled-components';
import useWindowSize from '../utils/useWindowSize';
import {LegalFooter} from './LegalFooter'

interface PageWrapperProps {
  page?: string;
  'data-id'?: string;
  children?: object;
}

const PageWrapper: React.FC<PageWrapperProps> = ({ children, page }) => {
  
  const size = useWindowSize();

  return (
    <div
      className={`pagewrapper ${page && page}`}
      style={{
                                                            //desktop   //mobile
      margin: size.width > 440 ?                            '0 auto' : '0 16px',
      maxWidth: size.width > 440 ?                          '730px' : '100%',
      padding: ((size.width > 440) && (page !== 'email')) ? '0 16px' : '0',
      height: '100%'
    }}>
      {children}
      <LegalFooter/>
    </div>
  )
}

export default PageWrapper;
