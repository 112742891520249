import React from 'react';
import Button from 'antd/es/button'
import SupercardHeaderLockup from '../components/SupercardHeaderLockup'
import {Link} from 'react-router-dom'
import PageWrapper from '../components/PageWrapper';
import { dustRed } from '../theme';

const Canceled = () => {
	return (
		<PageWrapper page={'email'}>
			<SupercardHeaderLockup text={'Canceled'}/>
			<br/>
			<p style={{
				fontSize: '24px',
				lineHeight: '1.2',
				color: 'black',
				margin: '0 0 80px 0'
			}}>Your purchase has been canceled. Tap below to start a new card.</p>

			<Link to={'/'}>
				<Button
				block
				size={'large'}
          style={{
            // width: '160px',
            // width: '49%',
            backgroundColor: dustRed[6],
            borderColor: dustRed[6],
            fontSize: '18px',
            height: '48px',
          }}
          // type="danger"
				>Return Home</Button>
			</Link>
		</PageWrapper>
	);
};

export default Canceled;
