import React from 'react';
import styled from 'styled-components';
import Outer from './Outer';
import Inner from './Inner';
import { useRouteMatch } from 'react-router-dom';
import Watermark from './Watermark';
import SupercardCornerBadgeSVGSC from './SupercardCornerBadgeSVG_SC';
// import 'typeface-roboto-condensed';
import { ToFromProps, CardBodyProps} from '../interfaces/cardInterfaces';
import './card022.scss';
import 'typeface-bowlby-one';

const fontFam = "'Bowlby One', sans-serif";
const fontWeight = 400;

const To = styled.span<ToFromProps>`
  position:absolute;
  width: 96%;
  top: 15%;
  margin: 0 0 0 0;
  z-index:1;

  font-size: ${(props) => props.cardWidthInPx / 9}px;
  text-align: center;

  color: ${props => props.textColor};
  letter-spacing: ${props => (props.cardWidthInPx > 160 ? 0 : 0)};

  font-family: ${fontFam};
  font-weight: ${fontWeight};

  display: inline-block;
  height: ${props => props.cardWidthInPx / 1.6}px;
  overflow: hidden;
  line-height: 1;
`;
To.defaultProps = {
  'data-id': 'To'
};

const From = styled.span<ToFromProps>`
  position:absolute;
  width: 94%;

  top: 70%;
  margin: 0 0 0 0;
  z-index:1;
  font-size: ${props => props.cardWidthInPx / 9}px;
  text-align:center;

  color: ${props => props.textColor};
  letter-spacing: ${props => (props.cardWidthInPx > 160 ? 0 : 0)};
  font-family: ${fontFam};
  font-weight: ${fontWeight};
  display: inline-block;
  height: ${props => props.cardWidthInPx * 0.28}px;
  overflow: hidden;
  line-height: 1.1;
`;
From.defaultProps = {
  'data-id': 'From'
};


const CardBody016: React.FC<CardBodyProps> = ({ to, from, cardWidthInPx })=>{
  let matchSelectedRoute = useRouteMatch('/selected');
  let matchEmailRoute = useRouteMatch('/email');

  return (
    <>
      <Outer cardWidthInPx={cardWidthInPx}>

        <Inner bcgColor={'#a8a8a8'}>
          {(matchSelectedRoute || matchEmailRoute) && (
            <Watermark
            />
          )}

          <To textColor={'black'} cardWidthInPx={cardWidthInPx}>
            {/* DROP A BEAT {to && to.toUpperCase()}. IT'S YR BIRTHDAY  */}
            {to && to.toUpperCase()}, YOUR BIRTHDAY IS MUSIC TO MY EARS.
          </To>

          <div className='cd-wrapper'>
            <div className="cd disc"/>
          </div>

          <From textColor={'red'} cardWidthInPx={cardWidthInPx}>
            &ndash; {from && from.toUpperCase()}
          </From>

          <SupercardCornerBadgeSVGSC widthinpx={cardWidthInPx} />

        </Inner>
      </Outer>
    </>
  );
};

export default CardBody016;