import React, { useEffect, useState } from 'react';

import { useParams, Link } from 'react-router-dom';

import CardBodyToRender from '../components/CardBodyToRender';
import Spin from 'antd/es/spin';
import Button from 'antd/es/button';
import styled from 'styled-components';
import { gray } from '../theme';
import StyledComponentsGlobalStyles from '../components/StyledComponentsGlobalStyles';
import { ReactComponent as SupercardBadgeSVG } from '../svgs/supercard-badge.svg';
import { Helmet } from 'react-helmet';
import sendIdQueryMongoForCardData from '../utils/sendIdQueryMongoForCardData';
import { UserObjectProps } from '../interfaces/cardInterfaces';

const Wrapper = styled.div<{ 'data-id'?: string }>`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  color: ${gray[6]};
  overflow: hidden;
  margin: 16px 0 24px 0;
`;
Wrapper.defaultProps = {
  'data-id': 'UniqueCardPage__Wrapper',
};

const handleReload = () => {
  window.location.reload();
  return false;
};

const whatToRender = (userObject: UserObjectProps | null) => {
  // If userObject NOT LOADED YET:
  if (!userObject) {
    return (
      <>
        <p style={{ fontSize: '16px', color: 'white' }}>
          Supercard™ loading...
        </p>
        <p style={{ fontSize: '14px' }}>
          Refresh browser if it doesn't appear in 2 secs!
        </p>
        <Spin style={{ margin: '32px 0 0 0' }} size="large" />
      </>
    );
  }

  // If userObject is an object returned correctly from mongodb:
  if (userObject && userObject.cardId) {
    return (
      <>
        <CardBodyToRender
          // This is the cardCode: "HBD___"
          cardQueryValue={userObject && userObject.cardId}
          to={userObject && userObject.to}
          from={userObject && userObject.from}
          cardWidthInPx={296}
        />

        <Link to={'/'}>
          <div style={{ margin: '16px 0 0 0', transform: 'scale(0.8)' }}>
            <SupercardBadgeSVG />
          </div>
        </Link>
      </>
    );
  }

  // If userObject is an error object from serverless function:
  if (userObject && userObject.error) {
    return (
      // IF USEROBJECT IS AN ERROR OBJ:
      <>
        <p style={{ color: 'white', margin: '16px 0 24px 0', fontSize:'16px' }}>
          Bummer, network error. Reload the page!
        </p>
        <Button style={{padding:'0 24px', height:'40px', margin: '0 0 16px 0' }} ghost onClick={handleReload}>
          RELOAD PAGE
        </Button>

        <Link to={'/'}>
          <div style={{ margin: '16px 0 0 0', transform: 'scale(0.8)' }}>
            <SupercardBadgeSVG />
          </div>
        </Link>
      </>
    );
  }
};

const UniqueCardPage = () => {
  // useParams accesses the URL parameter(s) implemented in the Route component:
  //    In this case, it is `_id` from <Route path="/card/:_id">
  // THIS is the unique card code user is trying to view right now, included in the URL
  let { _id } = useParams();

  const [userObject, setUserObject] = useState<UserObjectProps | null>(null);

  // ON MOUNT 1: GET THE CARD DATA FROM MONGO TO RENDER THE SAVED CARD:
  useEffect(() => {
    // INVOKE imported func:
    sendIdQueryMongoForCardData(_id, setUserObject);
  }, [_id]);

  useEffect(() => {
    console.log('userObj returned from Mongo (and now in state):', userObject);
  }, [userObject]);

  return (
    <Wrapper>
      <Helmet>
        <title>{`Card to ${userObject && userObject.to} from ${
          userObject && userObject.from
        }`}</title>
      </Helmet>

      <StyledComponentsGlobalStyles />

      {whatToRender(userObject)}
    </Wrapper>
  );
};

export default UniqueCardPage;
