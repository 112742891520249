import React from 'react';
import styled from 'styled-components';
import scarfaceCard from '../images/smartphone/smartphone7.jpg';
import Outer from './Outer';
import Inner from './Inner';
import { useRouteMatch } from 'react-router-dom';
import Watermark from './Watermark';
import SupercardCornerBadgeSVGSC from './SupercardCornerBadgeSVG_SC';
import { ToFromProps, CardBodyProps, BackgroundImageProps} from '../interfaces/cardInterfaces';

const To = styled.span<ToFromProps>`
  position:absolute;
  width: 90%;
  top: 10%;
  left: 10%;
  margin: 0 0 0 0;
  z-index:1;
  
  height: ${props => props.cardWidthInPx / 5.5}px;

  overflow: hidden;
  line-height: 1.1;
  /* TO CHANGE FONT SIZE: CHANGE THIS👇 DENOMINATOR. */
  font-size: ${props => props.cardWidthInPx / 12.5}px;
  text-align:left;

  color: ${props => props.textColor};
  letter-spacing: ${props => (props.cardWidthInPx > 160 ? 0 : '0em')};

  font-family: 'Roboto Condensed', sans-serif;
  font-weight:600;
  line-height: 1;
`;
To.defaultProps = {
  'data-id': 'To'
};


const From = styled.span<ToFromProps>`
  position:absolute;
  width: 89%;
  height: 80px;
  top: 80%;
  left: 10.5%;
  margin: 0 0 0 0;
  z-index:1;

  /* TO CHANGE FONT SIZE: CHANGE THIS👇 DENOMINATOR. */
  font-size: ${props => props.cardWidthInPx / 12.5}px;
  text-align:left;

  color: ${props => props.textColor};
  letter-spacing: ${props => (props.cardWidthInPx > 160 ? 0 : '0')};
  line-height: 1.1;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 600;
`;
From.defaultProps = {
  'data-id': 'From'
};

const BackgroundImage = styled.img<BackgroundImageProps>`
  display:block;
  position: absolute;
  width: ${props=>props.cardWidthInPx}px;
  opacity: 1;
  border-radius: 8px;
  
  height: 100%;
  object-fit: cover;
  
  z-index:0;
`;
BackgroundImage.defaultProps = {
  'data-id': 'BackgroundImage'
};


const CardBody014: React.FC<CardBodyProps> = ({ to, from, cardWidthInPx })=>{
  let matchSelectedRoute = useRouteMatch('/selected');
  let matchEmailRoute = useRouteMatch('/email');

  return (
    <>
      <Outer cardWidthInPx={cardWidthInPx}>

        {/*<Inner bcgColor={'hsla(259, 25%, 30%, 1)'}>*/}
        <Inner bcgColor={'black'}>
        {/*<Inner bcgColor={'hsla(348, 87%, 39%, 1)'}>*/}
          {(matchSelectedRoute || matchEmailRoute) && (
            <Watermark
            // ALL OPTIONAL PROPS (default values are applied in the TERNARY of WatermarkImgSC.
            width={50}
            top={50}
            left={50}
            />
          )}


          <To textColor={'red'} cardWidthInPx={cardWidthInPx}>
            HAPPY BIRTHDAY
            <br/>
            {to && to.toUpperCase()}
          </To>

          <From textColor={'red'} cardWidthInPx={cardWidthInPx}>
            &ndash; {from && from.toUpperCase()}
          </From>

          {/*<Link to={'/'}>*/}
          <SupercardCornerBadgeSVGSC widthinpx={cardWidthInPx} />
          {/*</Link>*/}

          {/*<From textColor={'#DF974B'} cardWidthInPx={cardWidthInPx}>From, {from}</From>*/}

          <BackgroundImage cardWidthInPx={cardWidthInPx} src={scarfaceCard} alt="card" />

        </Inner>
      </Outer>
    </>
  );
};

export default CardBody014;