import React from 'react';
// import styled from 'styled-components';

import PageWrapper from '../components/PageWrapper';
import StyledComponentsGlobalStyles from '../components/StyledComponentsGlobalStyles';
import SupercardHeaderLockup from '../components/SupercardHeaderLockup';
import { Helmet } from 'react-helmet';
import RedBadge from '../components/RedBadge';
import StyledH2 from '../components/StyledH2';

const HowItWorks = () => {
  return (
    <PageWrapper>
      <Helmet>
        <title>How It Works</title>
      </Helmet>

      <StyledComponentsGlobalStyles light />

      <SupercardHeaderLockup lightText={false} marTop={20} />

      <h1 className="page-title" style={{ padding: '32px 0 32px 0' }}>
        How It Works
      </h1>

      <RedBadge text={'STEP'} num={1} />
      <StyledH2>
        Select your card.
      </StyledH2>

      <RedBadge text={'STEP'} num={2} />
      <StyledH2>
        Customize the "To" and "From" sections.
      </StyledH2>

      <RedBadge text={'STEP'} num={3} />
      <StyledH2 margin={'0 0 0 0'}>
        Purchase and receive your Supercard link now. Send whenever you want.
        {/* Supercards are unique URL links you can share via any chat,
        email or social channel. */}
      </StyledH2>

      <div className="text-block">
        <h2 style={{ padding: '32px 0 16px 0' }}>
          Supercard™ is the easiest way to send the best digital greeting cards.
        </h2>

        <p>
          We set out to make cards we wanted to send (and receive{' '}
          <span role="img" aria-label="smiley">
            🙂
          </span>
          ). And then we made it as easy as possible to purchase and share them.
        </p>
      </div>

      <div className="text-block">
        <h2>Browse. Customize. Send.</h2>
        <p>
          Browse our cards. Follow the prompts to customize the "To" and "From"
          names. Pay securely via Stripe (including ApplePay and GooglePay).
          Receive a unique URL to your card.
        </p>
      </div>

      <div className="text-block">
        <h2>No Login. No Signup.</h2>
        <p>
          We don't require any login or signup whatsoever. Because we don't like
          to be forced to sign up either. Upon purchase, we ask for your email
          simply to provide your Supercard URL link.
        </p>
      </div>

      <div className="text-block">
        <h2>Supercards are shareable from anywhere.</h2>

        <p>
          Every Supercard™ is basically its own mini website. Upon purchase,
          you'll receive a unique link URL to your card.
<br/>
<br/>
          Because each Supercard™ is a URL, it can be shared via any messaging,
          email or social media platform: Instagram, Twitter, Facebook,
          iMessage, WeChat, Messenger. You name it.
        </p>

        {/* <p>
          Because the Supercard™ is a URL, they are compatible with every
          browser on every device, on every platform.
        </p> */}
      </div>
    </PageWrapper>
  );
};

export default HowItWorks;
