import React from 'react';
// import styled from 'styled-components';

import PageWrapper from '../components/PageWrapper';
import StyledComponentsGlobalStyles from '../components/StyledComponentsGlobalStyles';
import SupercardHeaderLockup from '../components/SupercardHeaderLockup';
import { Helmet } from 'react-helmet';

interface ContactProps { }
 
const Contact: React.FC<ContactProps> = (props: ContactProps) => {
  return (
    <PageWrapper>
      <Helmet>
        <title>Contact</title>
      </Helmet>

      <StyledComponentsGlobalStyles light />

      <SupercardHeaderLockup lightText={false} marTop={20} />

      <h1 className="page-title">Contact</h1>

      <div className="text-block">
        <p style={{ fontSize: '24px' }}>
          Questions? Suggestions? Get in touch.
        </p>
        <p>
          Please reach us at{' '}
          <a href="mailto:info@supercardco.com">info@supercardco.com</a>.
        </p>
      </div>
      <div style={{ height: '80px' }}></div>
    </PageWrapper>
  );
};

export default Contact;
