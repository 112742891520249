import React from 'react';
import styled from 'styled-components';
import Outer from './Outer';
import Inner from './Inner';
import { useRouteMatch } from 'react-router-dom';
import Watermark from './Watermark';
import SupercardCornerBadgeSVGSC from './SupercardCornerBadgeSVG_SC-white-text';
import drugs from '../images/drugs-400px.png'
import {
  ToFromProps,
  CardBodyProps,
  BackgroundImageProps,
} from '../interfaces/cardInterfaces';

import './card-styles.scss';


const To = styled.span<ToFromProps>`
  position: absolute;
  width: 94%;
  top: 10%;
  margin: 0 0 0 0;
  z-index: 1;
  transform-origin: top left;
  font-size: ${(props) => props.cardWidthInPx / 10}px;
  font-weight: 700;
  text-align: center;
  color: ${(props) => props.textColor};
  letter-spacing: ${(props) => (props.cardWidthInPx > 160 ? '0' : '0')};
  display: inline-block;
  height: ${(props) => props.cardWidthInPx / 1.4}px;
  overflow: hidden;
  line-height: 1;
`;
To.defaultProps = {
  'data-id': 'To',
};

const From = styled.span<ToFromProps>`
  position: absolute;
  width: 96%;
  top: 73%;
  margin: 0 0 0 0;
  z-index: 1;
  font-size: ${(props) => props.cardWidthInPx / 9}px;
  text-align: center;
  color: ${(props) => props.textColor};
  letter-spacing: ${(props) => (props.cardWidthInPx > 160 ? 0 : 0)};
  font-weight: 700;
  display: inline-block;
  height: ${(props) => props.cardWidthInPx / 3.1}px;
  overflow: hidden;
  line-height: 1;
`;
From.defaultProps = {
  'data-id': 'From',
};

const BackgroundImage = styled.img<BackgroundImageProps>`
  display: block;
  position: absolute;
  top: 44%;
  width: ${(props) => props.cardWidthInPx}px;
  opacity: 1;
  border-radius: 8px;
  height: 18%;
  object-fit: cover;
  z-index: 0;
  transform:scale(0.5);
  animation: wobble 5s ease-in-out infinite;
`;
BackgroundImage.defaultProps = {
  'data-id': 'BackgroundImage',
};

const Card: React.FC<CardBodyProps> = ({ to, from, cardWidthInPx }) => {
  let matchSelectedRoute = useRouteMatch('/selected');
  let matchEmailRoute = useRouteMatch('/email');

  return (
    <>
      <Outer cardWidthInPx={cardWidthInPx}>
        <Inner className={'gradientBcgAnimation2-radial'} bcgColor={'#320000'} justifyContent={'center'}>
          
          {(matchSelectedRoute || matchEmailRoute) && (
            <Watermark
              width={52}
              top={70}
              left={47}
            />
          )}

          <To textColor={'black'} cardWidthInPx={cardWidthInPx}>
            {`Happy Birthday ${to && to}. Don't do drugs. Unless you're doing them with me.`}
          </To>

          <From textColor={'white'} cardWidthInPx={cardWidthInPx}>
            {from && from}
          </From>

          <BackgroundImage
            cardWidthInPx={cardWidthInPx}
            src={drugs}
            alt="Happy birthday"
          />

          <SupercardCornerBadgeSVGSC widthinpx={cardWidthInPx} />
        </Inner>
      </Outer>
    </>
  );
};

export default Card;
