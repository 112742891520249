import React from 'react';
import styled from 'styled-components';
import watermark from '../images/watermark2.png';

interface WatermarkImgSCProps {
  'data-id'?: string;
  top?: number;
  left?: number;
  width?: number | undefined;
}

const WatermarkImgSC = styled.img<WatermarkImgSCProps>`
  display: block;
  position: absolute;
  top: ${(props) => (props.top ? props.top : 70)}%;
  left: ${(props) => (props.left ? props.left : 31)}%;
  z-index: 3;
  transform: rotate(-10deg);
  width: ${(props) => (props.width ? props.width : 66)}%;
  opacity: 0.1;
`;
WatermarkImgSC.defaultProps = {
  'data-id': 'WatermarkImgSC',
};

interface WatermarkProps {
  top?: number;
  left?: number;
  width?: number | undefined;
}

const Watermark: React.FC<WatermarkProps> = ({ top = 0, left = 0, width }) => {
  return (
    <>
      <WatermarkImgSC
        className={'noselect'}
        top={top}
        left={left}
        width={width}
        src={watermark && watermark}
        alt="watermark"
      />
    </>
  );
};

export default Watermark;
