// import React from 'react'
import styled from 'styled-components';
import Modal from 'antd/es/modal';
// import Input from 'antd/es/input';
// import Button from 'antd/es/button';


interface Props {
  'data-id'?: string;
  centered?: boolean;
  title?: string;
  visible?: boolean;
  onOk ?: ()=>void;
  onCancel ?: ()=>void;
  footer?: Array<any>;
}
const StyledModal = styled(Modal)<Props>`
  .ant-modal-title {
    color: white;
    font-size: 22px;
  }

  .ant-modal-content {
    background: black;
    padding: 8px 0px;

    > .ant-modal-header {
      background: black;
      border: none;
      padding: 16px 24px 16px;
    }

    > .ant-modal-body {
      padding-top: 8px;

      span {
        color: white;
      }

      > form > span:nth-child(7) {
        margin-bottom: 24px;
      }
    }

    > div.ant-modal-body > span:nth-child(3) {
      margin-bottom: 24px;
    }

    > .ant-modal-footer {
      background: black;
      border: none;
      padding-left: 24px;
      padding-right: 24px;

      > button {
        width: 100%;
      }
    }
  }
`;
StyledModal.defaultProps = {
  'data-id': 'Selected__StyledModal'
};

export default StyledModal;