import React from 'react';
import styled from 'styled-components';
import kangarooCard from '../images/kangaroo3.jpg';
import Outer from './Outer';
import Inner from './Inner';
import { useRouteMatch } from 'react-router-dom';
import Watermark from './Watermark';
import SupercardCornerBadgeSVGSC from './SupercardCornerBadgeSVG_SC';
import {
  ToFromProps,
  CardBodyProps,
  BackgroundImageProps,
} from '../interfaces/cardInterfaces';

const To = styled.span<ToFromProps>`
  position: absolute;
  top: 8.5%;
  left: 7%;

  letter-spacing: ${(props) => (props.cardWidthInPx > 160 ? 0 : 0)};

  font-size: ${(props) => props.cardWidthInPx / 9.75}px;
  color: ${(props) => props.textColor};

  margin: 0 0 0 0;
  z-index: 1;
  font-weight: 600;

  display: inline-block;
  height: ${(props) => props.cardWidthInPx / 4}px;
  overflow: hidden;
  line-height: 1.1;

`;
To.defaultProps = {
  'data-id': 'To',
};

const From = styled.span<ToFromProps>`
  position: absolute;
  top: 79%;
  left: 8%;

  letter-spacing: ${(props) => (props.cardWidthInPx > 160 ? 0 : 0)};

  font-size: ${(props) => props.cardWidthInPx / 10}px;
  color: ${(props) => props.textColor};
  font-weight: 600;
  margin: 0 0 0 0;
  z-index: 1;

  display: inline-block;
  height: ${(props) => props.cardWidthInPx / 4.4}px;
  overflow: hidden;
  line-height: 1.1;
`;
From.defaultProps = {
  'data-id': 'From',
};

const BackgroundImage = styled.img<BackgroundImageProps>`
  display: block;
  position: absolute;
  /* top: 15px; */
  width: ${(props) => props.cardWidthInPx}px;
  opacity: 1;
  border-radius: 8px;
  height: 100%;
  object-fit: cover;
  z-index: 0;
`;
BackgroundImage.defaultProps = {
  'data-id': 'BackgroundImage',
};

const CardBody002: React.FC<CardBodyProps> = ({ to, from, cardWidthInPx }) => {
  let matchSelectedRoute = useRouteMatch('/selected');
  let matchEmailRoute = useRouteMatch('/email');

  return (
    <>
      {/*<StyledComponentsGlobalStyles />*/}

      <Outer cardWidthInPx={cardWidthInPx}>
        <Inner>
          {(matchSelectedRoute || matchEmailRoute) && (
            <Watermark
            // top={60.5} left={29.5}
            />
          )}

          {/*<Link to={'/'}>*/}
          <SupercardCornerBadgeSVGSC widthinpx={cardWidthInPx} />
          {/*</Link>*/}

          <To textColor={'#DF974B'} cardWidthInPx={cardWidthInPx}>
            HAPPY BIRTHDAY
            <br/>
            {to && to.toUpperCase()}
          </To>

          <BackgroundImage
            cardWidthInPx={cardWidthInPx}
            src={kangarooCard}
            alt=""
          />

          <From textColor={'#DF974B'} cardWidthInPx={cardWidthInPx}>
            &ndash; {from && from}
          </From>
        </Inner>
      </Outer>
    </>
  );
};

export default CardBody002;
