import {useState, useEffect} from 'react';

// SOURCE: https://codesnippets.dev/droopytersen/hooks-core/useWindowSize.ts
const useWindowSize = () => {
  const [state, setState] = useState<{ width: number; height: number }>({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
      const handler = () => {
        setState({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      };
      window.addEventListener('resize', handler);
      return () => window.removeEventListener('resize', handler);
  }, []);

  return state;
};

export default useWindowSize;