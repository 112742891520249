import React, { useEffect, useState, useRef, useCallback } from 'react';
import useQuery from '../utils/useQuery';
import { gray, dustRed } from '../theme';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import Button from 'antd/es/button';
import Input from 'antd/es/input';
import CardBodyToRender from '../components/CardBodyToRender';
import StyledComponentsGlobalStyles from '../components/StyledComponentsGlobalStyles';
import PageWrapper from '../components/PageWrapper';
import './email.scss';
import validateEmailFormat from '../utils/validateEmailFormat';
import MailOutlined from '@ant-design/icons/MailOutlined';
import LockOutlined from '@ant-design/icons/LockOutlined'

declare global {
  interface Window {
    Stripe: any;
  }
}
interface BPCProps {
  'data-id'?: string;
}
const BlackPreviewContainer = styled.div<BPCProps>`
  background-color: ${gray[9]};
  height: 196px;
  padding: 16px 0;
  display: flex;
  margin: 0 0 16px 0;
`;
BlackPreviewContainer.defaultProps = {
  'data-id': 'EmailPage__BlackPreviewContainer',
};

const BottomSpace = styled.div<BPCProps>`
  background-color: ${gray[9]};
  height: 8px;
`;
BottomSpace.defaultProps = {
  'data-id': 'EmailPage__BottomSpace',
};

const Email = () => {
  let query = useQuery();

  const card: string | number | boolean | null = query.get('card');
  const to: string | number | boolean | null = query.get('to');
  const from: string | number | boolean | null = query.get('from');
  const is3d: string | number | boolean | null = query.get('is3d');

  const history = useHistory();

  const [emailInputVal, setEmailInputVal] = useState('');

  const [isEmailValid, setIsEmailValid] = useState<boolean>(true);

  const handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEmailInputVal(e.target.value);
  };

  // FOR MOBILE CONSIDERATION: ON MOUNT: scroll to top:
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const emailInputRef = useRef(null);
  // const continueButtonRef = useRef<HTMLButtonElement | null>(null);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();

      handleInputSubmit(emailInputVal);
    }
  };

  const handleGoBackClick = () => {
    history.go(-1);
  };

  const shortid = require('shortid');
  shortid.characters(
    '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ$@'
  );
  const shortidCode = shortid.generate();


  const runStripe: any = useCallback(
    (
      stripe: any
    ) => {
      stripe
        .redirectToCheckout({


          // TEST: CHANGE THIS!
          // items: [{ sku: 'sku_GUdnI3MEaf6inY', quantity: 1 }],
          // LIVE:
          items: [{ sku: 'sku_GT9c7FMPF2i6cq', quantity: 1 }],

          // Instead use one of the strategies described in
          // https://stripe.com/docs/payments/checkout/fulfillment

          // URL TO VISIT AFTER SUCCESSFUL PAYMENT:
          successUrl: `${
            process.env.NODE_ENV === 'development'
              ? 'http://localhost:3000'
              : `https://${window.location.hostname}`
          }/success?card=${card}&to=${to && encodeURIComponent(to)}&from=${
            from && encodeURIComponent(from)
          }&_id=${shortidCode}&is3d=${is3d}&email=${encodeURIComponent(
            emailInputVal
          )}`,
          cancelUrl: `${
            process.env.NODE_ENV === 'development'
              ? 'http://localhost:3000'
              : `https://${window.location.hostname}`
          }/canceled?card=${card}&to=${to && encodeURIComponent(to)}&from=${
            from && encodeURIComponent(from)
          }&_id=${shortidCode}`,
          customerEmail: emailInputVal,
        })

        .then(function (result: { error: { message: string } }) {
          if (result && result.error) {
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer.
            let displayError = document.getElementById('error-message');

            if (displayError) {
              displayError.textContent = result.error.message;
            }
          }
        });
    }, [card, emailInputVal, from, is3d, shortidCode, to]);


  // TEST: CHANGE THIS!
  // let stripe: () => void = window.Stripe(
  //   'pk_test_SXyp1qd09R1P1XjzHlEcXBtn00ON3ehUQu'
  // );
  // LIVE: CHANGE THIS!
  let stripe: () => void = window.Stripe(
    'pk_live_lSz9Y6xpXISznXbOFTlLGqy400UyvRZ6Ar'
  );


  useEffect(() => {
    // STRIPE VANILLA CODE!!

    let checkoutButton = document.getElementById(
      //   // LEAVE THIS AS IS
      'checkout-button-sku_GT9c7FMPF2i6cq'
    );

    if (checkoutButton && checkoutButton.style) {
      checkoutButton.style.cursor = 'pointer';
      checkoutButton.addEventListener<'click'>('click', runStripe(stripe));
    }
    // eslint-disable-next-line
  }, []);

  // let encoded;




  // USER CLICKS CONTINUE BUTTON:
  const handleInputSubmit: (emailValue: string) => void = (
    emailValue: string
  ) => {
    // 1. Validate email with Regex and receive boolean value
    const isValidEmailFormat = validateEmailFormat(emailValue);

    // 3. If the email is valid:
    if (isValidEmailFormat === true) {
      // 1. encode email string. To prep for use in query string.
      // encoded = encodeURIComponent(emailValue);

      // history.push(
      //   // `/payment?card=${card}&to=${to}&from=${from}&_id=${shortidCode}&email=${encoded}&is3d=${is3d}`
      //   `/payment?card=${card}&to=${to}&from=${from}&email=${encoded}&is3d=${is3d}`
      // );
      runStripe(stripe);

      
    } else {
      // return setIsEmailValid('invalid');
      setIsEmailValid(false);
    }
  };

  return (
    <PageWrapper page={'email'}>
      <StyledComponentsGlobalStyles />

      <div className={'header-labels'}>
        <span
          style={{
            display: 'block',
            fontSize: '18px',
            fontWeight: 300,
            color: gray[3],
            margin: '24px 0 0 0',
            // padding: '0 16px'
          }}
        >
          PREVIEW
        </span>

        <span
          style={{
            display: 'block',
            fontSize: '18px',
            fontWeight: 300,
            color: gray[3],
            margin: '24px 0 0 48px',
            // padding: '0 16px'
          }}
        >
          DETAILS
        </span>
      </div>

      {/* RED HORIZONTAL BORDER */}
      <hr
        style={{
          display: 'block',
          height: '1px',
          background: 'red',
          border: 0,
          margin: '8px 0 0 0',
          width: '100%',
        }}
      />

      <BlackPreviewContainer>
        {/* CARD PREVIEW RECT */}
        <div style={{ cursor: 'pointer' }} onClick={() => history.go(-1)}>
          {/* CARD IMAGE PREVIEW */}
          <CardBodyToRender
            cardQueryValue={card}
            // inputValues={{ to: to, from: from }}
            cardWidthInPx={100}
            to={to && to}
            from={from && from}
          />
        </div>

        <div
          className={'rightSideOfPreview'}
          style={{
            margin: '0 0 0 32px',
            textAlign: 'left',
            flex: 1,
            height: '164px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <div className="names-wrapper">
            <p
              style={{
                fontSize: '18px',
                color: 'white',
                margin: '0 0 4px 0',
                padding: '0',
                height: '32px',
                maxHeight: '32px',
                // truncate long names
                width: '208px',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              <span style={{ color: gray[6] }}>To: </span>
              <span>{to}</span>
            </p>

            <p
              style={{
                fontSize: '18px',
                color: 'white',
                margin: '0 0 8px 0',
                padding: '0',
                height: '32px',
                maxHeight: '32px',
                // truncate long names
                width: '208px',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              <span style={{ color: gray[6] }}>From: </span>
              <span>{from}</span>
            </p>
          </div>

          <p
            style={{
              fontSize: '18px',
              color: 'white',
              margin: '16px 0 0 0',
              padding: '0',
              height: '24px',
              // truncate long names
              width: '208px',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            <span style={{ color: gray[6] }}>Price: </span>
            <span>$1.99</span>
          </p>
        </div>
      </BlackPreviewContainer>

      <div className="email__input-wrapper">
        <p className={'email__enter-your-email'}>
          Enter your email to receive your unique Supercard link:
        </p>

        <Input
          ref={emailInputRef}
          // onKeyUp={handleKeyUp}
          onKeyPress={handleKeyPress}
          // onKeyPress={handleKeyPress}
          value={emailInputVal}
          className={'email__input'}
          placeholder="Enter your email"
          onChange={handleInputChange}
          // style={{padding: '0 0 0 16px'}}
          // onChange={handleInputSubmit(emailInputVal)}
          prefix={<MailOutlined style={{color:'red'}} />}
        />

        {/*RED ERROR TEXT*/}
        {!isEmailValid && (
          <p
            style={{
              display: 'block',
              fontSize: '16px',
              // color: dustRed[5],
              color: 'red',
              fontWeight: 700,
              maxWidth: '400px',
              margin: '8px 0 0 0',
            }}
          >
            Whoops! Email is not valid. Please try again.
          </p>
        )}

        <p className={'email__privacy'}>
          Privacy Policy: We absolutely do not share any email addresses with
          anyone.
        </p>
      </div>

      <div className="button-wrapper" style={{ padding: '40px 0 0 0' }}>
        <Button
          size={'large'}
          style={{
            // width: '160px',
            width: '48%',
            margin: '0 8px 0 0',
            fontSize: '18px',
            height: '48px',
          }}
          ghost
          onClick={handleGoBackClick}
        >
          Go back
        </Button>

        {/* <Link
          to={`/payment?card=${card}&to=${to}&from=${from}&is3d=${is3d}`}
        > */}
        {/* <a href="#" > */}
        <Button
          // onKeyPress={handleKeyPress}
          onClick={() => handleInputSubmit(emailInputVal)}
          size={'large'}
          style={{
            // width: '160px',
            width: '49%',
            backgroundColor: dustRed[6],
            borderColor: dustRed[6],
            fontSize: '18px',
            height: '48px',
            color:'white'
          }}
          // type="danger"
          // id="checkout-button-sku_GT9c7FMPF2i6cq"
        >

          {/* <Icon
            type="lock"
            style={{ marginRight: '0px' }} /> */}
          <LockOutlined />
          Pay & Finish
          {/* <Icon type="right" /> */}
        </Button>
        {/* </a> */}
        {/* </Link> */}
      </div>
    </PageWrapper>
  );
};

export default Email;
