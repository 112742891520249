import React from 'react';
import styled from 'styled-components';
import morganFreeman from '../images/shortsize-cards/morgan-short.jpg';
import Outer from './Outer';
import Inner from './Inner';
import { useRouteMatch } from 'react-router-dom';
import Watermark from './Watermark';
import SupercardCornerBadgeSVGSC from './SupercardCornerBadgeSVG_SC';
import {
  ToFromProps,
  CardBodyProps,
  BackgroundImageProps,
} from '../interfaces/cardInterfaces';

const To = styled.span<ToFromProps>`
  position: absolute;
  top: 6.5%;
  left: 7%;

  letter-spacing: ${(props) => (props.cardWidthInPx > 160 ? 0 : 0)};

  font-size: ${(props) => props.cardWidthInPx / 9}px;
  color: ${(props) => props.textColor};

  margin: 0 0 0 0;
  z-index: 1;
  font-weight: 500;

  display: inline-block;
  height: ${(props) => props.cardWidthInPx / 7.5}px;
  overflow: hidden;
  line-height: 1.1;
`;
To.defaultProps = {
  'data-id': 'To',
};

const From = styled.span<ToFromProps>`
  position: absolute;
  top: 86%;
  left: 8%;

  letter-spacing: ${(props) => (props.cardWidthInPx > 160 ? 0 : 0)};

  font-size: ${(props) => props.cardWidthInPx / 10}px;
  color: ${(props) => props.textColor};
  font-weight: 500;
  margin: 0 0 0 0;
  z-index: 1;

  display: inline-block;
  height: ${(props) => props.cardWidthInPx / 4.4}px;
  overflow: hidden;
  line-height: 1.1;
`;
From.defaultProps = {
  'data-id': 'From',
};

const BackgroundImage = styled.img<BackgroundImageProps>`
  display: block;
  position: absolute;
  width: ${(props) => props.cardWidthInPx}px;
  opacity: 1;
  border-radius: 8px;

  height: 100%;
  object-fit: cover;
  z-index: 0;
`;
BackgroundImage.defaultProps = {
  'data-id': 'BackgroundImage',
};

const CardBody004: React.FC<CardBodyProps> = ({ to, from, cardWidthInPx }) => {
  let matchSelectedRoute = useRouteMatch('/selected');
  let matchEmailRoute = useRouteMatch('/email');

  return (
    <>
      {/*<StyledComponentsGlobalStyles />*/}

      <Outer cardWidthInPx={cardWidthInPx}>
        <Inner>
          {(matchSelectedRoute || matchEmailRoute) && (
            <Watermark
            // top={60.5} left={29.5}
            />
          )}

          {/*<Link to={'/'}>*/}
          <SupercardCornerBadgeSVGSC widthinpx={cardWidthInPx} />
          {/*</Link>*/}

          <To textColor={'#C17659'} cardWidthInPx={cardWidthInPx}>
            {to && to}
          </To>

          <BackgroundImage
            cardWidthInPx={cardWidthInPx}
            src={morganFreeman}
            alt=""
          />

          <From textColor={'#C17659'} cardWidthInPx={cardWidthInPx}>
            &ndash; {from && from}
          </From>
        </Inner>
      </Outer>
    </>
  );
};

export default CardBody004;
