import {UserObjectProps} from '../interfaces/cardInterfaces'

// interface userDocOrNullShape{
//   _id: string;
// }

// DEFINE the func:
// async function sendIdQueryMongoForCardData(_id: string, setUserObject: (object: userDocOrNullShape) => void) {
async function sendIdQueryMongoForCardData(_id: string, setUserObject: React.Dispatch<React.SetStateAction<UserObjectProps | null>>) {
  try {
    // 1. Send a req, receive a response. Store it in `res`:
    const res = await fetch('/api/getCardById', {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },                  // key: '5shsn79'
      body: JSON.stringify({ idKey: _id }),
    });

    const userDocOrNull: UserObjectProps | null = await res.json();
    
    // NOTE:
    // `userDocOrNull` is one of THREE things: a Document OBJECT,
    // an Error Object with shape
    //  {
    //    error: {
    //      code: "502"
    //      message: "An error occurred with this application."
    //      }
    //  },
    // or NULL

    // SHAPE OF RETURNED OBJECT if it is not NULL -> `{ _id: '5d273f0' }`
    if (userDocOrNull && userDocOrNull._id) {
      console.log('✅ 1. All good. The _id exists as expected, and has been found.');
      setUserObject(userDocOrNull);
    }

    //if the id sent into the req does NOT match a doc from MongoDB, log this, send Mailgun email, and add data to Mongo
    // if (data && data._id && (data._id !== _id)) {
    if (userDocOrNull === null) {
      console.log('Bad news. The card was not found, which is a problem.');
    }

    // error returned from server:
    if (userDocOrNull && userDocOrNull.error) {
      console.log('❌ Bad news. Error object returned from serverless func.');
      setUserObject(userDocOrNull);
    }

    console.log("Raw return value from '/api/getCardById': ", userDocOrNull);
    console.log("typeof userDocOrNull: ", typeof userDocOrNull);

  } catch (e) {
    console.log('Error_from_the_catch_block:', e);
  }
  // finally {
  //   console.log("Raw return value from '/api/getCardById': ", userDocOrNull)
  // }
}

export default sendIdQueryMongoForCardData;