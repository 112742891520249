import * as THREE from 'three';
import React, { useMemo, useRef } from 'react';
import { useLoader, useUpdate, useFrame } from 'react-three-fiber';

export const TextGroup: React.FC<{ children: object }> = ({ children }) => {
  
  const ref = useRef<{ rotation: { x: number; y: number; z: number } }>();

  useFrame(({ clock }) => {
    if (ref && ref.current) {
      return (ref.current.rotation.x = ref.current.rotation.y = ref.current.rotation.z =
        Math.sin(clock.getElapsedTime()) * 0.3);
    }
  });

  return <group ref={ref}>{children}</group>;
};

interface TextProps {
  children?: string ;
  [key: string]: any;
}

export const Text: React.FC<TextProps> = ({
  children,
  vAlign = 'center',
  hAlign = 'center',
  size = 1,
  color = '#000000',
  ...props
}) => {
  // const font = useLoader(THREE.FontLoader, '/bold.blob')

  // WORKS AFTER A FEW SECS
  // const font = useLoader(THREE.FontLoader, 'fonts/Damion_Regular.json');

  // THESE DO NOT WORK
  // const font = useLoader(THREE.FontLoader, 'fonts/gentilis_bold.typeface.json');
  // const font = useLoader(THREE.FontLoader, 'fonts/gentilis_regular.typeface.json');

  // WORKS FAST!!
  const font = useLoader(
    THREE.FontLoader,
    '../fonts/circular-air-bold_regular.json'
  );

  // const font = useLoader(
  //   THREE.FontLoader,
  //   '../fonts/droid_sans_bold.typeface.json'
  // );

  // useMemo(): Returns a memoized value.
  // Only Recomputes when one of the deps changes.
  // A more selective form of useEffect: Avoids expensive calculations on every render.
  const configObj = useMemo(
    // func returns the config object. Changes only when font changes
    () => ({
      font,
      size: 30,
      height: 30,
      // curveSegments: 32,
      bevelEnabled: true,
      bevelThickness: 2,
      bevelSize: 2.5,
      // bevelOffset: 0,
      bevelSegments: 8,
    }),
    [font]
  );

  const mesh = useUpdate(
    (self: {
      geometry: {
        computeBoundingBox: () => void;
        boundingBox: { getSize: (size: {}) => void };
      };
      position: {x: number, y: number};
    }) => {
      const size = new THREE.Vector3();
      self.geometry.computeBoundingBox();
      self.geometry.boundingBox.getSize(size);
      self.position.x =
        hAlign === 'center' ? -size.x / 2 : hAlign === 'right' ? 0 : -size.x;
      self.position.y =
        vAlign === 'center' ? -size.y / 2 : vAlign === 'top' ? 0 : -size.y;
    },
    [children]
  );

  return (
    <group {...props} scale={[0.1 * size, 0.1 * size, 0.1]}>
      <mesh ref={mesh}>
        <textGeometry attach="geometry" args={[children!, configObj]} />
        <meshPhongMaterial attach="material" color={color} />
      </mesh>
    </group>
  );
};
