import React from 'react';
import styled from 'styled-components';
import TodayCard from '../images/topgun/today3.jpg';

import Outer from './Outer';
import Inner from './Inner';
import { useRouteMatch } from 'react-router-dom';
import Watermark from './Watermark';
// import SupercardCornerBadgeSVGSC from './SupercardCornerBadgeSVG_SC-white-stroke';
// import SupercardCornerBadgeSVGSC from './SupercardCornerBadgeSVG_SC-white-text';
import SupercardCornerBadgeSVGSC from './SupercardCornerBadgeSVG_SC';
import 'typeface-roboto-condensed';
import { ToFromProps, CardBodyProps, BackgroundImageProps} from '../interfaces/cardInterfaces';

const fontFam = "'Roboto Condensed', sans-serif";
const fontWeight = 700;

const To = styled.span<ToFromProps>`
  position:absolute;
  width: 100%;
  top: 18%;
  margin: 0 0 0 0;
  z-index:1;

  font-size: ${(props) => props.cardWidthInPx / 9.5}px;
  text-align: center;

  color: ${props => props.textColor};
  letter-spacing: ${props => (props.cardWidthInPx > 160 ? 0 : 0)};

  font-family: ${fontFam};
  font-weight: ${fontWeight};

  display: inline-block;
  height: ${props => props.cardWidthInPx * 0.11}px;
  overflow: hidden;
  line-height: 1.2;
`;
To.defaultProps = {
  'data-id': 'To'
};

const From = styled.span<ToFromProps>`
  position:absolute;
  width: 100%;

  top: 84%;
  /* left: 46%; */
  margin: 0 0 0 0;
  z-index:1;

  /* TO CHANGE FONT SIZE: CHANGE THIS👇 DENOMINATOR. */
  font-size: ${props => props.cardWidthInPx / 11}px;
  text-align:center;

  color: ${props => props.textColor};
  letter-spacing: ${props => (props.cardWidthInPx > 160 ? 0 : 0)};
  
  font-family: ${fontFam};
  font-weight: ${fontWeight};

  display: inline-block;
  /* height: 72px; */
  height: ${props => props.cardWidthInPx * 0.28}px;
  overflow: hidden;
  line-height: 1.1;
`;
From.defaultProps = {
  'data-id': 'From'
};



const BackgroundImage = styled.img<BackgroundImageProps>`
  display:block;
  position: absolute;
  width: ${props=>props.cardWidthInPx}px;
  opacity: 1;
  border-radius: 8px;
  
  height: 100%;
  object-fit: cover;
  z-index:0;
`;
BackgroundImage.defaultProps = {
  'data-id': 'BackgroundImage'
};


const CardBody016: React.FC<CardBodyProps> = ({ to, from, cardWidthInPx })=>{
  let matchSelectedRoute = useRouteMatch('/selected');
  let matchEmailRoute = useRouteMatch('/email');

  return (
    <>
      <Outer cardWidthInPx={cardWidthInPx}>

        {/*<Inner bcgColor={'hsla(259, 25%, 30%, 1)'}>*/}
        <Inner bcgColor={'white'}>
        {/*<Inner bcgColor={'hsla(348, 87%, 39%, 1)'}>*/}
          {(matchSelectedRoute || matchEmailRoute) && (
            <Watermark
            // ALL OPTIONAL PROPS (default values are applied in the TERNARY of WatermarkImgSC.
            // width={70}
            // top={60.5} left={29.5}
            />
          )}


          <To textColor={'#0051BE'} cardWidthInPx={cardWidthInPx}>
            {to && to.toUpperCase()}
          </To>

          <From textColor={'red'} cardWidthInPx={cardWidthInPx}>
            &ndash; {from && from.toUpperCase()}
          </From>

          {/*<Link to={'/'}>*/}
          <SupercardCornerBadgeSVGSC widthinpx={cardWidthInPx} />
          {/*</Link>*/}

          <BackgroundImage cardWidthInPx={cardWidthInPx} src={TodayCard} alt="card" />
        </Inner>
      </Outer>
    </>
  );
};

export default CardBody016;