import React from 'react';
import {
  // gray,
  dustRed
  // daybreakBlue
} from '../theme';

import {
  // Link,
  useHistory
  // useLocation,
  // useRouteMatch
} from 'react-router-dom';

// import Icon from 'antd/es/icon';
import LeftOutlined from '@ant-design/icons/LeftOutlined'
import styled from 'styled-components';

interface LabelProps {
  'data-id'?: string;
}
const Label = styled.span<LabelProps>`
  font-size: 16px;
  margin: 0 0 0 4px;
  color: white;
  font-weight: 500;
  /* position: relative;
  bottom: -1px; */
`;
Label.defaultProps = {
  'data-id': 'CaretAndBack__Label'
};

const BackButton = styled.button`
  border: none;
  &:hover{
    cursor:pointer;
  }
`;

const CaretAndBack = () => {
  const history = useHistory();
  // const location = useLocation();

  const handleLinkClick = () => {
    // history.go(-1);
    history.go(-1);
  };

  return (
    <>
        <BackButton
          onClick={handleLinkClick}
          // id={'Link'}
          // to={location => ({ ...location, pathname: "/courses" })}
        >
          {/* <Icon style={{ color: dustRed[5] }} type="left" /> */}
        {/* <Icon /> */}
        <LeftOutlined style={{ color: dustRed[5] }}  />
          <Label>Back</Label>
          {/*{*/}
          {/*  console.log('HISTORY', history)*/}
          {/*}*/}
          {/*{*/}
          {/*  console.log('LOCATION', location)*/}
          {/*}*/}
        </BackButton>
    </>
  );
};

export default CaretAndBack;
