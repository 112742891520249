import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Home from './pages/home';
import TopAppBar from './components/topAppBar';
import Email from './pages/email';
import Selected from './pages/selected';
import Success from './pages/success';
import Canceled from './pages/canceled';
import createPersistedState from 'use-persisted-state';
import UniqueCardPage from './pages/UniqueCardPage';
import Faq from './pages/faq';
import HowItWorks from './pages/howitworks';
import Contact from './pages/contact';
import { useSelector } from 'react-redux';
import { ErrorFallback } from './components/ErrorFallback/ErrorFallback';
import { ErrorBoundary } from 'react-error-boundary';

const usePersistedToState = createPersistedState('to');
const usePersistedFromState = createPersistedState('from');

function App() {
  let matchSelectedRoute = useRouteMatch('/selected');
  let matchCardRoute = useRouteMatch('/card');
  let matchEmailRoute = useRouteMatch('/email');

  const [to, setTo] = usePersistedToState('Alex🥳');
  const [from, setFrom] = usePersistedFromState('Love, Cameron💜');

  const cardData = useSelector((state) => state.cardReducer);

  return (
    <>
      <div className="App">
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onReset={() => {
            setTo('Alex🥳');
            setFrom('Love, Cameron💜');
          }}
          resetKeys={[to, from]}
        >
          {/* prevent render of TopAppBar on /selected, /card, /email pages */}
          {!matchSelectedRoute && !matchCardRoute && !matchEmailRoute && (
            <TopAppBar className={'TopAppBar'} id={'TopAppBar'} />
          )}

          <Switch>
            <Route path="/card/:_id">
              <UniqueCardPage />
            </Route>

            <Route path="/faq">
              <Faq />
            </Route>

            <Route path="/howitworks">
              <HowItWorks />
            </Route>

            <Route path="/support">
              <Contact />
            </Route>

            <Route path="/contact">
              <Contact />
            </Route>

            <Route path="/success">
              <Success />
            </Route>

            <Route path="/canceled">
              <Canceled />
            </Route>

            <Route path="/email">
              <Email />
            </Route>

            <Route path="/selected">
              <Selected
                cardData={cardData}
                to={to}
                from={from}
                setTo={setTo}
                setFrom={setFrom}
              />
            </Route>

            <Route path="/">
              <Home
                cardData={cardData}
                to={to}
                from={from}
                setTo={setTo}
                setFrom={setFrom}
              />
            </Route>
          </Switch>
        </ErrorBoundary>
      </div>
    </>
  );
}

export default App;
