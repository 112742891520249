import React from 'react';
import styled from 'styled-components';
import { ReactComponent as TysonRounded } from '../svgs/sexy2.svg';
import tyson from '../images/tyson-only.png'
import Outer from './Outer';
import Inner from './Inner';
import { useRouteMatch } from 'react-router-dom';
import Watermark from './Watermark';
import SupercardCornerBadgeSVGSC from './SupercardCornerBadgeSVG_SC-white-text';
import {
  ToFromProps,
  CardBodyProps,
  BackgroundImageProps,
} from '../interfaces/cardInterfaces';

const To = styled.span<ToFromProps>`
  position: absolute;
  top: 7%;
  text-align: center;
  width: 96%;
  letter-spacing: ${(props) => (props.cardWidthInPx > 160 ? 0 : '0')};
  font-size: ${(props) => props.cardWidthInPx / 9}px;
  color: ${(props) => props.textColor};
  margin: 0 0 0 0;
  z-index: 1;
  font-weight: 700;

  display: inline-block;
  height: ${(props) => props.cardWidthInPx / 1.85}px;
  overflow: hidden;
  line-height: 1.15;
`;
To.defaultProps = {
  'data-id': 'To',
};

const From = styled.span<ToFromProps>`
  position: absolute;
  top: 82%;
  text-align: center;
  width: 96%;
  letter-spacing: ${(props) => (props.cardWidthInPx > 160 ? 0 : '0')};
  font-size: ${(props) => props.cardWidthInPx / 9}px;
  color: ${(props) => props.textColor};
  font-weight: 700;
  margin: 0 0 0 0;
  z-index: 1;
  display: inline-block;
  height: ${(props) => props.cardWidthInPx / 3.1}px;
  overflow: hidden;
  line-height: 1.1;
`;
From.defaultProps = {
  'data-id': 'From',
};

const BackgroundImage = styled.img<BackgroundImageProps>`
  display: block;

  position: absolute;
  width: ${(props) => props.cardWidthInPx}px;
  top: 59%;
  left: 4%;
  margin-top: -${(props) => props.cardWidthInPx * 0.75}px;
  transform: scale(0.54);

  z-index: 0;
`;
BackgroundImage.defaultProps = {
  'data-id': 'BackgroundImage',
};

const TysonRoundedSC = styled(({ cardWidthInPx, ...rest }) => (
  <TysonRounded {...rest} />
))`
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -${(props) => props.cardWidthInPx * 0.5}px;

  animation: rotation 20s infinite linear;
  z-index:9;
`;

const Card: React.FC<CardBodyProps> = ({ to, from, cardWidthInPx }) => {
  let matchSelectedRoute = useRouteMatch('/selected');
  let matchEmailRoute = useRouteMatch('/email');

  return (
    <>
      {/*<StyledComponentsGlobalStyles />*/}

      <Outer cardWidthInPx={cardWidthInPx}>
        <Inner bcgColor={'gray'} className={'gradientBcgAnimation2'} >
          {(matchSelectedRoute || matchEmailRoute) && (
            <Watermark
            />
          )}

          <To textColor={'black'} cardWidthInPx={cardWidthInPx}>
            {to && to}
          </To>

          <TysonRoundedSC id={'welcome'} cardWidthInPx={cardWidthInPx} />

          <From textColor={'white'} cardWidthInPx={cardWidthInPx}>
            {from && from}
          </From>

          <SupercardCornerBadgeSVGSC widthinpx={cardWidthInPx} />

          <BackgroundImage
            cardWidthInPx={cardWidthInPx}
            src={tyson}
            alt="Happy birthday"
          />

        </Inner>
      </Outer>
    </>
  );
};

export default Card;
