import React from 'react';
import PropTypes from 'prop-types';
// import Typography from 'antd/es/typography';
import { gray } from '../theme';
import styled from 'styled-components';
// const { Title } = Typography;
import {ReactComponent as LockupBlackTextSVG} from '../svgs/LOCKUP_BLACK-TEXT.svg';
import {ReactComponent as LockupWhiteTextSVG} from '../svgs/LOCKUP_WHITE-TEXT.svg';
import {Link} from 'react-router-dom';

interface SupercardProps{
  light: boolean
}
const Supercard = styled.h1<SupercardProps>`
  color: ${props=>props.light ? gray[1] : gray[10]};
  margin: 0;
  font-size: 50px;
  font-weight: 600;
  letter-spacing: -0.005em;
`;

// const DigitalBirthdayGreetings = styled.h2`
//   color: ${dustRed[6]};
//   margin: -10px 0 24px 0;
//   font-size: 21px;
//   font-weight: 400;
// `;

// export interface Props{
//   marTop: number;
//   marTop16: boolean;
//   marLeft16: boolean;
//   top16:boolean;
//   width: number;
//   height: number;
//   lightText: boolean;
//   text: string;
//   push16: Boolean;
// }
interface SupercardHeaderLockupProps{
  marTop?: number;
  marTop16?: boolean;
  marLeft16?: boolean;
  top16?: boolean;
  width?: number;
  height?: number;
  lightText?: boolean;
  text?: string;
  push16?: boolean;
}
const SupercardHeaderLockup :React.FC<SupercardHeaderLockupProps> = ({marTop, marTop16, marLeft16, top16, width = 240, height, lightText = false, text = '', push16}) => {
  return (

    <div className={'HeaderLockupWrapper'} style={{
      position:'relative',
      // top: `${top16 ? '16px' : 0 }`,
      left:`${push16 ? 16 : 0 }px`,
      margin: '24px 0 0 0',
      marginTop: `${marTop}px`,
      marginLeft: `${marLeft16 ? 16 : 0 }px`
      // padding: '0 16px',
    }}>

      {
        text
      ?
        <Supercard light={lightText} >
          {text}™
        </Supercard>
      :
          <Link to={'/'} >
          {
           lightText
            ?
              <LockupWhiteTextSVG width={width} height={height}/>
            :
              <LockupBlackTextSVG width={width} height={height}/>
          }
          </Link>
      }
    </div>
  );
};

SupercardHeaderLockup.propTypes = {
  lightText: PropTypes.bool,
  text: PropTypes.string,
  push16: PropTypes.bool
};


export default SupercardHeaderLockup;
