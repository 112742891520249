import React from 'react';
import styled from 'styled-components';
import { dustRed } from '../theme';
import Icon from 'antd/es/icon';
import { Link, useRouteMatch } from 'react-router-dom';
// import cardData from '../cardData';
// import JsxParser from 'react-jsx-parser';
// import { default as JsxParser } from 'react-jsx-parser';
import './HDCardWithLabel.scss';

interface WrapperProps {
  'data-id'?: string;
  remove16pxRightMargin?: boolean;
  cardWidthInPx?: number | undefined;
}
const Wrapper = styled.div<WrapperProps|any>`
  margin: ${(props) =>
    props && props.remove16pxRightMargin ? '16px 0 16px 0' : '16px 11px 16px 0'};

  position: relative;
  max-width: ${(props) =>
    props && props.cardWidthInPx > 160 ? '100%' : '160px' };
`;
Wrapper.defaultProps = {
  'data-id': 'HDCardWithLabel__Wrapper',
};

interface LabelTitleProps {
  'data-id'?: string;
  cardWidthInPx?: number;
}
const LabelTitle = styled.h4<LabelTitleProps>`
  margin: 10px 0 0 0;
  width: ${(props) => props.cardWidthInPx};
  font-size: 16px;
  font-weight: 500;
  line-height: 1.3;
`;
LabelTitle.defaultProps = {
  'data-id': 'HDCardWithLabel__LabelTitle',
};

interface Props {
  'data-id'?: string;
}
const Price = styled(LabelTitle)<Props>`
  margin: 0;
  color: ${dustRed[6]};
  font-size: 14px;
  font-weight: 400;
`;
Price.defaultProps = {
  'data-id': 'HDCardWithLabel__Price',
};

const IconPriceWrapper = styled.div<Props>`
  display: flex;
  align-items: center;
`;
IconPriceWrapper.defaultProps = {
  'data-id': 'HDCardWithLabel__IconPriceWrapper',
};

const StyledIcon = styled(Icon)<Props>`
  color: red;
`;
StyledIcon.defaultProps = {
  'data-id': 'HDCardWithLabel__StyledIcon',
};

interface HDCardWithLabelProps {
  setCardId?: any;
  cardId?: string | undefined;
  to?: string;
  from?: string;
  is3d?: boolean;
  handleCardClick?: (
    cardId: string | undefined,
    to: string | undefined,
    from: string | undefined,
    is3d: boolean | undefined
  ) => void;
  showModal?: () => void;
  children?: object;
  cardWidthInPx?: number;
  clickUrl?: any;
  labelTitle: string | null;
  price: number;
  remove16pxRightMargin?: boolean;
  handleOk?: () => void;
  artist?: string | null;
}

const HDCardWithLabel: React.FC<HDCardWithLabelProps> = ({
  setCardId,
  to,
  from,
  handleCardClick,
  showModal,
  cardId,
  children,
  cardWidthInPx,
  clickUrl,
  labelTitle,
  price,
  remove16pxRightMargin,
  is3d,
  artist,
}) => {
  let matchSelectedRoute = useRouteMatch('/selected');

  const handleOnClick = () => {
    if (matchSelectedRoute) {
      showModal && showModal();
    } else {
      handleCardClick && handleCardClick(cardId, to, from, is3d);
    }
  };

  return (
    <Link className={'Link'} onClick={handleOnClick} to={clickUrl}>
      <Wrapper
        className={'Wrapper'}
        remove16pxRightMargin={remove16pxRightMargin}
        cardWidthInPx={cardWidthInPx}
      >
        {/* card rendered as children */}
        {children}

        <LabelTitle cardWidthInPx={cardWidthInPx}>
          {labelTitle}

        </LabelTitle>
          {/* <span className="pipe">-</span> */}

          {/* <br /> */}
          
          <span className={'artist'}>
            <span role="img" aria-label='art emoji'>🎨</span> by {artist ? artist : 'Juump'}
          </span>


        <IconPriceWrapper>
          <Price className={'price'}>${price}</Price>
        </IconPriceWrapper>
      </Wrapper>
    </Link>
  );
};

export default HDCardWithLabel;
