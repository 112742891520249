import React from 'react';
import { cardData } from '../utils/useCardData';

import {
  // ToFromProps,
  CardBodyToRenderProps,
  // BackgroundImageProps,
} from '../interfaces/cardInterfaces';

const CardBodyToRender: React.FC<CardBodyToRenderProps> = ({
  // cardQueryValue='HB001',
  cardQueryValue,
  // cardWidthInPx = 160,
  cardWidthInPx,
  // inputValues = { to: 'DemoTo', from: 'DemoFrom' },
  to = '',
  from = '',
}) => {

  // find the card object in the array, which the user selected
  const userSelectedCardObject = cardData.find(
    // find by the object's cardId, accessed from props
    // "return the object in the array which has the matching cardId value, selected by the user"
    (cardObj) => cardObj.cardId === cardQueryValue
  );

  // Get the `component` property value, and store it
  const selectedComponent =
    userSelectedCardObject && userSelectedCardObject.component;

  // GITHUB: https://github.com/microsoft/TypeScript/issues/28631#issuecomment-448649454

  // const Component: React.ReactType | undefined = selectedComponent;
  // const Component = selectedComponent ? 'div' : 'div' ;
  // const Component = selectedComponent && 'div' ;
  // const Component = React.createElement(selectedComponent);
  // const Component:React.FC<CardBodyProps>| undefined = selectedComponent ;
  // const Component:React.FC<CardBodyProps>| undefined = React.createElement(selectedComponent) ;
  // const Component:DetailedReactHTMLElement<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> | undefined = React.createElement(selectedComponent) ;
  // const Component:React.ReactType<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> | undefined = React.createElement(selectedComponent) ;
  // const Component:React.ReactType<any> | undefined = React.createElement(selectedComponent) ;
  // const Component:React.ReactType | undefined = React.createElement(selectedComponent) ;
  // const Component:React.ReactElement | undefined = React.createElement(selectedComponent) ;
  // const Component:React.ReactElementType | undefined = React.createElement(selectedComponent) ;
  // const Component: any = React.createElement(selectedComponent);

  // convert the card object's `component` property value into a captialized variable to become the dynamic component to render
  const Component: any = selectedComponent;

         // ie 'HB001'
  switch (cardQueryValue) {

    //dynamic single case which renders the user-selected card:
    case cardQueryValue:
      return (
        <Component
          to={to ? to : ''}
          from={from ? from : ''}
          cardWidthInPx={cardWidthInPx}
        />
      );

    default:
      return (
        <p style={{ marginTop: '16px', width: '250px', fontSize: '18px' }}>
          Whoops, network bug. Please refresh the page.
        </p>
      );
  }
};

export default CardBodyToRender;
