import styled from 'styled-components';
import {ReactComponent as SupercardCornerBadgeSVG} from '../svgs/new-badge-white-text.svg';

interface Props{
  widthinpx?: number
}
const handleWidthSwitch: (props: Props) => number = (props)=>{
 switch(props.widthinpx) {
	 case 100:
		 return .19;
	 case 160:
		 return .26;
	 default:
		 return .49
 }
};

const SupercardCornerBadgeSVGSC = styled(SupercardCornerBadgeSVG)<Props>`
  position: absolute;
  bottom: 2%;
  right: 4%;
  transform-origin: bottom right;
  
  /* NEW SCALE BASED ON WIDTH: */
  transform: scale(${props=>(
  	handleWidthSwitch(props)
	)});
	
  z-index:2;
`;

export default SupercardCornerBadgeSVGSC;
