import React from 'react';
import { ReactComponent as Laurel } from '../svgs/laurel.svg';

interface LaurelWreathQuoteProps {
  text: string;
  rightMarginInPx?: number;
  name: string;
}

const LaurelWreathQuote: React.FC<LaurelWreathQuoteProps> = ({
  text,
  rightMarginInPx,
  name,
}) => {
  return (
    <div
      className="laurel-outer"
      style={{
        width: '160px',
        margin: `0 ${rightMarginInPx}px 0 0`,
      }}
    >
      <div
        className="laurel-container"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <div className="laurel-wrapper">
          {/* SVG */}
          <Laurel />
        </div>

        <p
          style={{
            fontSize: '15px',
            color: 'red',
            width: '110px',
            height: '48px',
            textAlign: 'center',
            lineHeight: 1.1,
            position: 'absolute',
            left: '24px',
          }}
          data-testid="ptag-text"
        >
          "{text}"
        </p>

        <div className="laurel-wrapper" style={{ transform: 'scaleX(-1)' }}>
          <Laurel />
        </div>
      </div>

      <p
        className="name"
        data-testid="ptag-name"
        style={{
          textAlign: 'center',
        }}
      >
        &mdash; {name}
      </p>
    </div>
  );
};

export default LaurelWreathQuote;
