import React, { useState } from 'react';
import styled from 'styled-components';
import HDCardWithLabel from '../components/HDCardWithLabel';
import SupercardHeaderLockup from '../components/SupercardHeaderLockup';
import PageWrapper from '../components/PageWrapper';
import StyledComponentsGlobalStyles from '../components/StyledComponentsGlobalStyles';
import Button from 'antd/es/button';
import CustomMailchimpForm from '../components/CustomMailchimpForm';
import Testimonials from '../components/Testimonials';
import LivePreview from '../components/LivePreview';
import Pagination from 'antd/es/pagination';
import './home.scss';
import { CardDataObject } from '../interfaces/cardInterfaces';
interface CardFlexContainerProps {
  'data-id'?: string;
}
const CardFlexContainer = styled.div<CardFlexContainerProps>`
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;
`;
CardFlexContainer.defaultProps = {
  'data-id': 'Home__CardFlexContainer',
};

interface HomeProps {
  handleCardClick?: () => void;
  setCardId?: any;
  to: string;
  from: string;
  setTo: any;
  setFrom: any;
  cardData: Array<CardDataObject>;
}

const cardWidthInPx = 160;

const Home: React.FC<HomeProps> = (props) => {
  const { to, from, setTo, setFrom, cardData } = props;

  const [pagination, setPagination] = useState({
    cards: [...cardData],
    currentPage: 1,
    cardsPerPage: 8,
  });

  const resetState = () => {
    setTo('Alex🥳');
    setFrom('Love, Cameron💜');
  };

  const onPaginationChange = (page: number) => {
    setPagination({
      ...pagination,
      currentPage: page,
    });
    window.scrollTo(0, 200);
  };

  const indexOfLastCard = pagination.currentPage * pagination.cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - pagination.cardsPerPage;
  const currentCards = pagination.cards.slice(
    indexOfFirstCard,
    indexOfLastCard
  );

  return (
    <PageWrapper page="home">
      <StyledComponentsGlobalStyles light />

      <SupercardHeaderLockup lightText={false} marTop={20} width={240} />

      <h1
        style={{
          fontSize: '24px',
          padding: '10px 0 4px 0',
          fontWeight: 600,
          lineHeight: '1.2',
          maxWidth: '600px',
        }}
      >
        The easiest way to send the best birthday cards.
      </h1>

      <h2
        style={{
          fontSize: '20px',
          padding: '4px 0 10px 0',
          fontWeight: 600,
          lineHeight: '1.2',
          maxWidth: '600px',
        }}
      >
        Purchase now, send on their birthday. Tap to customize.
      </h2>

      {/* cards in order of display */}
      <CardFlexContainer>
        {/* render all cards from array of card objects */}
        {/* {cardData.map((item, index) => { */}
        {currentCards.map((item, index) => {
          const Component = item.component;

          return (
            <HDCardWithLabel
              key={index}
              to={to}
              from={from}
              cardId={item.cardId}
              clickUrl={`/selected?card=${item.cardId}&title=${item.title}&is3d=${item.is3d}`}
              labelTitle={item.title}
              price={item.price}
              cardWidthInPx={cardWidthInPx}
              is3d={item.is3d}
              artist={item.artist}
            >
              <Component to={to} from={from} cardWidthInPx={cardWidthInPx} />
            </HDCardWithLabel>
          );
        })}
      </CardFlexContainer>

      <div className="pagination-wrapper">
        <Pagination
          current={pagination.currentPage}
          defaultCurrent={1}
          // Default number of data items per page
          defaultPageSize={8}
          // Number of data items per page
          pageSize={8}
          onChange={onPaginationChange}
          total={pagination.cards.length}
          showTotal={(total) => `VIEW MORE CARDS: Total ${total}`}
        />
      </div>

      <LivePreview {...props} />

      <Button onClick={resetState}>Reset names</Button>

      <Testimonials />

      {/* email form section */}
      <div
        className={'email-section-wrapper'}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          // MORE STYLES IN SCSS
        }}
      >
        <h3 style={{ fontWeight: 600 }}>
          Get new Supercard&trade; designs and updates in your inbox{' '}
          <span role="img" aria-label="lightning bolt icon">
            ⚡️
          </span>
        </h3>

        <CustomMailchimpForm />

        <p
          style={{
            display: 'block',
            fontSize: '11px',
            color: 'black',
            fontWeight: 300,
            maxWidth: '400px',
            margin: '0 0 48px 0',
            textAlign: 'center',
          }}
        >
          <span role="img" aria-label="lock icon">
            🔒{' '}
          </span>{' '}
          Privacy Policy: We absolutely do not share your email with anyone.
        </p>
      </div>
    </PageWrapper>
  );
};

export default Home;
