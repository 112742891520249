import React from 'react';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import './CustomMailchimpForm.scss'
const url =
  '//supercardco.us10.list-manage.com/subscribe/post?u=839659751f5af617949292d04&amp;id=190610b5be';

interface CustomFormProps{
  status?: string | null;
  message?: string | null;
  onValidated?: ({
    EMAIL
    // NAME: name.value,
  }: {EMAIL: string}) => void;
}

const CustomForm: React.FC<CustomFormProps> = ({ status, message, onValidated }) => {
  
  let email:any;
  // let name;

  const submit = () =>
    email &&
    // name &&
    email.value.indexOf('@') > -1 &&
    onValidated!({
      EMAIL: email.value,
      // NAME: name.value,
    });

  return (
    <>
      {!status && (
        <div className="custom-form-inner">
          <input
            ref={(node) => (email = node)}
            type="email"
            placeholder="Your email"
            className="email-text-input"
            style={{fontWeight: 500}}
          />
          <br />
          <button style={{fontWeight: 500}} className="submit-button" onClick={submit}>
            Submit
          </button>
        </div>
      )}
      <div className="status-wrapper">
        {status === 'sending' && (
          <span style={{ color: 'blue' }} className="sending">
            sending...
          </span>
        )}

        {status === 'error' && (
          <span
            className="error"
            style={{ color: 'red' }}
            dangerouslySetInnerHTML={{ __html: message! }}
          />
        )}

        {status === 'success' && (
          <span
            className="success"
            style={{ color: 'green' }}
            dangerouslySetInnerHTML={{ __html: message! }}
          />
        )}
      </div>
    </>
  );
};

// use the render prop and your custom form
const CustomMailchimpForm = () => (
  <MailchimpSubscribe
    url={url}
    render={({ subscribe, status, message }) => (
      <div className="custom-form-wrapper">
        <CustomForm
          // onSubmitted={(formData) => subscribe(formData)}
          status={status}
          message={message}
          onValidated={(formData) => subscribe(formData)}
        />
      </div>
    )}
  />
);

export default CustomMailchimpForm;
