import React, { useState, useEffect } from 'react';
import useQuery from '../utils/useQuery';
import styled from 'styled-components';
import { gray, dustRed } from '../theme';
import HDCardWithLabel from '../components/HDCardWithLabel';
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import message from 'antd/es/message';
import { Link } from 'react-router-dom';
import CardBodyToRender from '../components/CardBodyToRender';
import StyledComponentsGlobalStyles from '../components/StyledComponentsGlobalStyles';
import StyledModal from '../components/StyledModal';
import { useHistory } from 'react-router-dom';
import {sunsetOrange} from '../theme'
import {CardDataObject} from '../interfaces/cardInterfaces'
import LeftOutlined from '@ant-design/icons/LeftOutlined'
import RightOutlined from '@ant-design/icons/RightOutlined'

interface Props {
  'data-id'?: string;
}
const Wrapper = styled.div<Props>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  color: black;
  overflow: hidden;
  margin: 0 0 40px 0;
`;
Wrapper.defaultProps = {
  'data-id': 'Selected__Wrapper',
};

const FixedBottomBar = styled.div<Props>`
  width: 100%;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  background: rgba(0, 0, 0, 0.1);
  text-align: center;
  position: fixed;
  bottom: 0;
  z-index: 99;
`;
FixedBottomBar.defaultProps = {
  'data-id': 'Selected__FixedBottomBar',
};

message.config({
  // distance from top
  top: 8,
  // time before auto-dismiss, in seconds
  duration: 4,
  // max message show, drop oldest if exceed limit
  maxCount: 1,
});

// model of props used by Selected component
interface Props {
  to?: string;
  from?: string;
  setTo?: any;
  setFrom?: any;
  // cardData: object[]  //<--never use
  // cardData?: Array<any> // easy out
  cardData?: Array<CardDataObject>
}

const cardWidthInPx = 296;

const Selected: React.FC<Props> = ({ to, from, setTo, setFrom, cardData }) => {
  const [visible, setVisible] = useState(false);

  let query = useQuery();
  const card = query.get('card');
  const title = query.get('title');
  const is3d = query.get('is3d');


  const selectedCardObj: CardDataObject|undefined = cardData && cardData.find((cardObj) => (cardObj && cardObj.cardId === card));
  // const selectedCardObj: object|undefined = cardData && cardData.find((cardObj) => (cardObj && cardObj.cardId === card));

  // top message:
  const info = () => {
    message.info('Tap the card to edit TO and FROM names.');
  };

  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
    message.destroy();

    setTimeout(() => {
      info();
    }, 600); // show the message pane after 850ms
  }, []);

  // remove message on unmount
  useEffect(() => {
    // returned function in useEffect: Will be called on component unmount
    return () => {
      message.destroy();
    };
  }, []);

  // show the center modal, and remove the top message pane
  const showModal = () => {
    setVisible(true);
    message.destroy();
  };

  const handleOk = () => {
    setVisible(!visible);
    // show top message pane:
    info();
  };

  const handleInputChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void = (e) => {
    const { name, value } = e.target;

    if (name === 'to') {
      setTo(value);
    }

    if (name === 'from') {
      setFrom(value);
    }
  };

  const handleInputClick = () => {
    history.push(
      `/selected?card=${card}&title=${title}&is3d=${is3d}&to=${to}&from=${from}`
    );
    handleOk();
  };

  // close modal if you click around the modal
  const handleCancel = () => {
    setVisible(!visible);
    info();
  };

  return (
    <>
      <Wrapper>
        
        <StyledComponentsGlobalStyles light />

       

        <HDCardWithLabel
          clickUrl={`/selected?card=${card}&title=${title}&is3d=${is3d}`}
          price={1.99}
          cardWidthInPx={cardWidthInPx}
          remove16pxRightMargin={true}
          showModal={showModal}
          handleOk={handleOk}
          labelTitle={title}
          artist={selectedCardObj ? selectedCardObj.artist : ''}
        >
          {/* conditionally renders one card */}
          <CardBodyToRender
            cardQueryValue={card}
            to={to}
            from={from}
            cardWidthInPx={cardWidthInPx}
          />

        {/* render notice if a R3F card is shown */}
        {
          card === 'HB006' && (
            <div
              style={{
                zIndex: 5,
                position: 'absolute',
                top: '492px',
                left: '44px',
                width: '268px',
                fontSize: '12px',
                padding: '8px',
                backgroundColor: 'rgba(255,255,255,0.8)',
                borderRadius: '4px',
                // boxShadow:'0 0 6px rgba(0, 0, 4, 0.15)',
                fontFamily: 'Arial, sans-serif',
                border: `1px solid ${sunsetOrange[5]}`,
                color: sunsetOrange[6],
              }}>
              <span role='img' aria-label='warning'>⚠️ </span>
               Note: This 3D card does not permit emojis because it uses a special 3D typeface
               <span role='img' aria-label='smile'> 🙂</span>
            </div>
          )
        }
        </HDCardWithLabel>

        
        <FixedBottomBar>

          <Link to={'/'} onClick={() => message.destroy()}>
            <Button
              size={'large'}
              style={{ backgroundColor: gray[10], border: gray[10], color:'white' }}
              // type="danger"
            >
              <LeftOutlined />
              Back
            </Button>
          </Link>

          <Link
            onClick={() => message.destroy()}
            to={`/email?card=${card}&to=${to}&from=${from}&is3d=${is3d}`}
          >
            <Button
              size={'large'}
              style={{ backgroundColor: dustRed[6], borderColor: dustRed[6], color:'white' }}
              // type="danger"
            >
              Continue
              {/* <Icon type="right" /> */}
              <RightOutlined />
              
            </Button>
          </Link>
          
        </FixedBottomBar>

        
        <StyledModal
          centered={true}
          title='Add "To" and "From" names:'
          visible={visible}
          onOk={handleInputClick}
          onCancel={handleCancel}
          footer={[
            <Button
              onClick={handleInputClick}
              onSubmit={handleInputClick}
              size={'large'}
              style={{ backgroundColor: dustRed[6], border: dustRed[6] }}
              key="submit"
              type="primary"
            >
              Add to card
            </Button>,
          ]}
        >
          <form>
            <span>To (Friend's name):</span>

            <label htmlFor={'to'} />
            <Input
              name={'to'}
              onPressEnter={handleInputClick}
              value={to}
              allowClear
              onChange={handleInputChange}
              size="large"
              placeholder="Recipient’s Name"
            />

            <br />
            <span>From (Your name):</span>

            <label htmlFor={'from'} />
            <Input
              name={'from'}
              onPressEnter={handleInputClick}
              value={from}
              allowClear
              onChange={handleInputChange}
              size="large"
              placeholder="Sender’s Name"
            />
          </form>

        </StyledModal>
      </Wrapper>
    </>
  );
};

export default Selected;
