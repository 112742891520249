import React, { Suspense,
  // useEffect, useRef, useState
} from 'react';
import styled from 'styled-components';
import Outer from './Outer';
import Inner from './Inner';
import { useRouteMatch } from 'react-router-dom';
import Watermark from './Watermark';
import SupercardCornerBadgeSVGSC from './SupercardCornerBadgeSVG_SC';
import * as THREE from 'three'; // eslint-disable-line
import {
  // extend,
  Canvas,
  // useLoader,
  // useFrame,
  // useThree
} from 'react-three-fiber';
// import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

import { Text } from './3D/Text';
import { TextGroup } from './3D/Text';
import {
  ToFromProps,
  CardBodyProps,
  BackgroundImageProps,
} from '../interfaces/cardInterfaces';
// import './styles.css'
// import { Controls } from './3D/Controls';

// extend({ OrbitControls });


const To = styled.span<ToFromProps>`
  position:absolute;
  top: 12%;
  left: 6.25%;
  
  letter-spacing: ${props => (props.cardWidthInPx > 160 ? 0 : '-.04em')};
  
  font-size: ${props => props.cardWidthInPx / 16}px;
  color: ${props => props.textColor};
  
  margin: 0 0 0 0;
  z-index:1;
  font-weight:500;
  // font-family: 'Big Shoulders Text';
`;
To.defaultProps = {
  'data-id': 'To'
};

const From = styled.span<ToFromProps>`
  width: 95%;
  text-align: center;
  position:absolute;
  bottom: 11%;
  left: 3%;
  letter-spacing: ${props => (props.cardWidthInPx > 160 ? 0 : '-.04em')};
  font-size: ${props => props.cardWidthInPx / 11}px;
  color: ${props => props.textColor};
  font-weight:500;
  margin: 0 0 0 0;
  z-index:1;
`;
From.defaultProps = {
  'data-id': 'From'
};

const BackgroundImage = styled.img<BackgroundImageProps>`
  display: block;
  position: absolute;
  width: ${props => props.cardWidthInPx}px;
  opacity: 1;
  border-radius: 8px;
  height: 100%;
  object-fit: cover;
  z-index: 0;
`;
BackgroundImage.defaultProps = {
  'data-id': 'BackgroundImage'
};

function removeEmojis (string: string) {
  var regex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;

  return string.replace(regex, '');
}

const CardBody006: React.FC<CardBodyProps> = ({ to, from, cardWidthInPx }) => {
  let matchSelectedRoute = useRouteMatch('/selected');
  let matchEmailRoute = useRouteMatch('/email');

  return (
    <>
      <Outer cardWidthInPx={cardWidthInPx}>
        <Inner>
          {(matchSelectedRoute || matchEmailRoute) && (
            <Watermark
            />
          )}

          <SupercardCornerBadgeSVGSC widthinpx={cardWidthInPx} />

          <Canvas
            // styles applied to the generated parent wrapper div
            style={{ borderRadius: '8px', position: 'relative', top: '-32px' }}
            camera={{ position: [0, 0, 15] }}
            concurrent
          >
            <ambientLight intensity={0.1} />
            <pointLight position={[300, 200, 1000]} />

            <Suspense fallback={'loading...!'}>

              <TextGroup>
                  <group>
                      <Text
                        hAlign="center"
                        position={[0, 2.3, 0]}
                        children="HAPPY"
                        size={0.4}
                        color={'pink'}
                      />

                      <Text
                        hAlign="center"
                        position={[0, 0, 0]}
                        children="BIRTHDAY"
                        size={0.6}
                        color={'hotpink'}
                      />

                      <Text
                        hAlign="center"
                        position={[0, -3, 0]}
                        // check if emoji is present
                        children={to && removeEmojis(to).toUpperCase()}
                        size={0.8}
                        color={'red'}
                      />
                  </group>
              </TextGroup>
            </Suspense>

            {/* <Controls /> */}

          </Canvas>

          <From textColor={'red'} cardWidthInPx={cardWidthInPx}>
            &ndash; {from}
          </From>
        </Inner>
      </Outer>
    </>
  );
};

export default CardBody006;