import React from 'react';
import styled from 'styled-components';
import Outer from './Outer';
import Inner from './Inner';
import { useRouteMatch } from 'react-router-dom';
import Watermark from './Watermark';
import SupercardCornerBadgeSVGSC from './SupercardCornerBadgeSVG_SC-white-text';
import { ReactComponent as LongTime } from '../svgs/starwars/long-time-ago-2.svg';
import './card007.css';
import 'typeface-work-sans';
import { ToFromProps, CardBodyProps } from '../interfaces/cardInterfaces';

const To = styled.span<ToFromProps>`
  display: block;
  position: absolute;

  top: 11%;

  transform-origin: center;
  letter-spacing: ${(props) =>
    props.cardWidthInPx > 160 ? '0.04em' : '0.04em'};

  font-size: ${(props) => props.cardWidthInPx / 9}px;
  margin: 0 0 0 0;
  z-index: 1;
  font-weight: 900;

  font-family: 'Work Sans';

  /* -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: ${(props) => props.cardWidthInPx / 240}px;
  -webkit-text-stroke-color: gold; */
  text-align: center;
  color: gold;

  display: inline-block;
  width: 96%;
  height: ${(props) => props.cardWidthInPx / 4.3}px;
  overflow: hidden;
  line-height: 1.1;
`;
To.defaultProps = {
  'data-id': 'To',
};

const From = styled.span<ToFromProps>`
  position:absolute;
  /* bottom: ${(props) => props.cardWidthInPx / 5}px; */
  top: 81%;
  
  letter-spacing: ${(props) => (props.cardWidthInPx > 160 ? '.1em' : '-.04em')};
  font-size: ${(props) => props.cardWidthInPx / 12}px;
  color: ${(props) => props.textColor};
  
  font-weight:900;
  margin: 0 0 0 0;
  z-index:1;
  
  -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: ${(props) => props.cardWidthInPx / 240}px;
  -webkit-text-stroke-color: gold;
  
  font-family: 'Work Sans', sans-serif;
  text-align: center;
  
  width: 96%;
  display: inline-block;
  height: ${(props) => props.cardWidthInPx / 4.8}px;
  overflow: hidden;
  line-height: 1.1;
`;
From.defaultProps = {
  'data-id': 'From',
};

interface SvgLockupProps {
  cardWidthInPx?: number;
  color?: string;
}
const SvgLockup: React.FC<SvgLockupProps> = ({ cardWidthInPx, color }) => {
  return (
    <div
      className={'flexParent'}
      style={{
        // textAlign: 'center',
        position: 'absolute',
        zIndex: 5,
        width: `${cardWidthInPx}px`,
        overflow: 'hidden',
      }}
    >
      <LongTime
        // fill={color}
        width={'100%'}
        height={'100%'}
        // style={{ margin: '0 0 6% 0' }}
      />
    </div>
  );
};

const CardBody007: React.FC<CardBodyProps> = ({ to, from, cardWidthInPx }) => {
  let matchSelectedRoute = useRouteMatch('/selected');
  let matchEmailRoute = useRouteMatch('/email');

  return (
    <>
      {/*<StyledComponentsGlobalStyles />*/}

      <Outer cardWidthInPx={cardWidthInPx}>
        <Inner
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '8px',
          }}
        >
          {(matchSelectedRoute || matchEmailRoute) && (
            <Watermark
            // ALL OPTIONAL PROPS (default values are applied in the TERNARY of WatermarkImgSC.
            // width={70}
            // top={60.5} left={29.5}
            />
          )}

          {/*HBD YODA BEST SVG TEXT:*/}
          <SvgLockup
          // cardWidthInPx={cardWidthInPx}
          // color={'gold'}
          />

          <div className={'container'}>
            <div className="scene">
              <div className="wrap">
                <div className="wall wall-right" />
                <div className="wall wall-left" />
                {/* <div className="wall wall-top" /> */}
                {/* <div className="wall wall-bottom" /> */}
                {/* <div className="wall wall-back" /> */}
              </div>

              <div className="wrap">
                <div className="wall wall-right" />
                <div className="wall wall-left" />
                {/* <div className="wall wall-top" /> */}
                {/* <div className="wall wall-bottom" /> */}
                {/* <div className="wall wall-back" /> */}
              </div>
            </div>
          </div>

          {/*<Link to={'/'}>*/}
          <SupercardCornerBadgeSVGSC widthinpx={cardWidthInPx} />
          {/*</Link>*/}

          <To textColor={'gold'} cardWidthInPx={cardWidthInPx}>
            {to && to.toUpperCase()}
          </To>

          {/*<BackgroundImage cardWidthInPx={cardWidthInPx} src={valueCard2} alt="" />*/}

          <From textColor={'gold'} cardWidthInPx={cardWidthInPx}>
            &ndash; {from && from.toUpperCase()}
          </From>
        </Inner>
      </Outer>
    </>
  );
};

export default CardBody007;
