import React from 'react';
import styled from 'styled-components';
import HBDLogo from '../images/playstation/HBD_black.gif';
import { ReactComponent as HBDType } from '../images/playstation/HBD_type.svg';

import Outer from './Outer';
import Inner from './Inner';
import { useRouteMatch } from 'react-router-dom';
import Watermark from './Watermark';
import SupercardCornerBadgeSVGSC from './SupercardCornerBadgeSVG_SC-white-text';
import {
  ToFromProps,
  CardBodyProps,
  BackgroundImageProps,
} from '../interfaces/cardInterfaces';

const To = styled.span<ToFromProps>`
  position: absolute;
  top: 55%;

  text-align: center;
  width: 100%;

  letter-spacing: ${(props) => (props.cardWidthInPx > 160 ? '.02em' : '.02em')};

  font-size: ${(props) => props.cardWidthInPx / 6.5}px;
  color: ${(props) => props.textColor};

  margin: 0 0 0 0;
  z-index: 1;
  /* font-weight: 300; */
  font-family: 'Zrnic', sans-serif;

  animation: flicker 0.15s infinite;

  display:inline-block;
  height: ${props => props.cardWidthInPx * 0.35}px;
  overflow: hidden;
  line-height: 1.15;

  @keyframes flicker {
    from {
      opacity: 1;
    }
    to {
      opacity: 0.8;
    }
  }
`;
To.defaultProps = {
  'data-id': 'To',
};

const From = styled.span<ToFromProps>`
  position: absolute;
  top: 75%;

  text-align: center;
  width: 100%;

  letter-spacing: ${(props) => (props.cardWidthInPx > 160 ? '.02em' : '.02em')};

  font-size: ${(props) => props.cardWidthInPx / 8.5}px;
  color: ${(props) => props.textColor};
  font-weight: 300;
  margin: 0 0 0 0;
  z-index: 1;
  font-family: 'Zrnic', sans-serif;

  display:inline-block;
  height: ${props => props.cardWidthInPx / 3.8}px;
  overflow: hidden;
  line-height: 1.05;
`;
From.defaultProps = {
  'data-id': 'From',
};

const BackgroundImage = styled.img<BackgroundImageProps>`
  display: block;

  position: absolute;
  width: ${(props) => props.cardWidthInPx}px;
  top: 50%;
  margin-top: -${(props) => props.cardWidthInPx * 0.5}px;
  transform: scale(0.5);

  z-index: 0;
`;
BackgroundImage.defaultProps = {
  'data-id': 'BackgroundImage',
};

// LOGO
const ImgSC = styled.img`
  animation: flicker 0.15s infinite;

  @keyframes flicker {
    from {
      opacity: 1;
    }
    to {
      opacity: 0.8;
    }
  }
`;

// TYPE
const HBDTypeSC = styled(({ cardWidthInPx, ...rest }) => <HBDType {...rest} />)`
  animation: flicker 0.15s infinite;

  @keyframes flicker {
    from {
      opacity: 1;
    }
    to {
      opacity: 0.8;
    }
  }
`;

const CardBody013: React.FC<CardBodyProps> = ({ to, from, cardWidthInPx }) => {
  let matchSelectedRoute = useRouteMatch('/selected');
  let matchEmailRoute = useRouteMatch('/email');

  return (
    <>
      <Outer cardWidthInPx={cardWidthInPx}>
        {/*<Inner bcgColor={'hsla(259, 25%, 30%, 1)'}>*/}
        <Inner bcgColor={'black'}>
          {/*<Inner bcgColor={'hsla(348, 87%, 39%, 1)'}>*/}
          {(matchSelectedRoute || matchEmailRoute) && (
            <Watermark
            // ALL OPTIONAL PROPS (default values are applied in the TERNARY of WatermarkImgSC.
            // width={70}
            // top={60.5} left={29.5}
            />
          )}

          <To textColor={'white'} cardWidthInPx={cardWidthInPx}>
            {to && to}
          </To>

          <ImgSC
            src={HBDLogo}
            alt=""
            style={{
              top: '19%',
              width: '74%',
              position: 'absolute',
            }}
          />

          <HBDTypeSC
            style={{
              width: '82%',
              position: 'absolute',
              top: '48%',
              left: '12%',
            }}
          />

          <From textColor={'red'} cardWidthInPx={cardWidthInPx}>
            {/* &mdash; {from && from} */}
            <span style={{ fontFamily: 'Arial, sans-serif' }}>
              &ndash;
            </span>{' '}
            {from && from}
          </From>

          <SupercardCornerBadgeSVGSC widthinpx={cardWidthInPx} />
        </Inner>
      </Outer>
    </>
  );
};

export default CardBody013;
