import React from 'react';
import styled from 'styled-components';
import FrostedFlakesCard from '../images/frostedflakes/cereal.jpg';

import Outer from './Outer';
import Inner from './Inner';
import { useRouteMatch } from 'react-router-dom';
import Watermark from './Watermark';
import SupercardCornerBadgeSVGSC from './SupercardCornerBadgeSVG_SC';
import 'typeface-kanit';
import {
  ToFromProps,
  CardBodyProps,
  BackgroundImageProps,
} from '../interfaces/cardInterfaces';

const To = styled.span<ToFromProps>`
  position: absolute;
  width: 87%;

  top: 21%;
  left: 16%;
  margin: 0 0 0 0;
  z-index: 1;

  transform-origin: top left;

  /* TO CHANGE FONT SIZE: CHANGE THIS👇 DENOMINATOR. */
  font-size: ${(props) => props.cardWidthInPx / 8}px;
  text-align: left;

  color: ${(props) => props.textColor};
  letter-spacing: ${(props) => (props.cardWidthInPx > 160 ? '0' : '0')};

  font-family: 'Kanit', cursive;
  font-style: italic;
  font-weight: 600;
  /* -webkit-text-stroke: .75px white; */

  transform: rotate(-11deg);
  /* skew(-25deg); */
  display: inline-block;
  height: ${(props) => props.cardWidthInPx * 0.13}px;
  overflow: hidden;
  line-height: 1.1;
`;
To.defaultProps = {
  'data-id': 'To',
};

const From = styled.span<ToFromProps>`
  position: absolute;
  width: 86%;

  top: 57%;
  left: 16%;
  margin: 0 0 0 0;
  z-index: 1;

  /* TO CHANGE FONT SIZE: CHANGE THIS👇 DENOMINATOR. */
  font-size: ${(props) => props.cardWidthInPx / 11}px;
  text-align: left;

  color: ${(props) => props.textColor};
  letter-spacing: ${(props) => (props.cardWidthInPx > 160 ? 0 : 0)};

  transform-origin: top left;
  transform: rotate(-11deg);

  font-family: 'Kanit', sans-serif;
  font-style: italic;
  font-weight: 600;

  display: inline-block;
  height: ${(props) => props.cardWidthInPx / 2.02}px;
  overflow: hidden;
  line-height: 1.05;
`;
From.defaultProps = {
  'data-id': 'From',
};

const BackgroundImage = styled.img<BackgroundImageProps>`
  display: block;
  position: absolute;
  width: ${(props) => props.cardWidthInPx}px;
  opacity: 1;
  border-radius: 8px;

  height: 100%;
  object-fit: cover;
  z-index: 0;
`;
BackgroundImage.defaultProps = {
  'data-id': 'BackgroundImage',
};

const CardBody015: React.FC<CardBodyProps> = ({ to, from, cardWidthInPx }) => {
  let matchSelectedRoute = useRouteMatch('/selected');
  let matchEmailRoute = useRouteMatch('/email');

  return (
    <>
      <Outer cardWidthInPx={cardWidthInPx}>
        {/*<Inner bcgColor={'hsla(259, 25%, 30%, 1)'}>*/}
        <Inner bcgColor={'black'}>
          {/*<Inner bcgColor={'hsla(348, 87%, 39%, 1)'}>*/}
          {(matchSelectedRoute || matchEmailRoute) && (
            <Watermark
              // ALL OPTIONAL PROPS (default values are applied in the TERNARY of WatermarkImgSC.
              width={51}
              top={56}
              left={53}
            />
          )}

          <To textColor={'white'} cardWidthInPx={cardWidthInPx}>
            {to && to.toUpperCase()}
          </To>

          <From textColor={'white'} cardWidthInPx={cardWidthInPx}>
            &ndash; {from && from.toUpperCase()}
          </From>

          {/*<Link to={'/'}>*/}
          <SupercardCornerBadgeSVGSC widthinpx={cardWidthInPx} />
          {/*</Link>*/}

          {/*<From textColor={'#DF974B'} cardWidthInPx={cardWidthInPx}>From, {from}</From>*/}

          <BackgroundImage
            cardWidthInPx={cardWidthInPx}
            src={FrostedFlakesCard}
            alt="card"
          />
        </Inner>
      </Outer>
    </>
  );
};

export default CardBody015;
